import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MachinesComponent } from "./pages/machines/machines.component";
import { MachinesOverviewComponent } from "./pages/machines-overview/machines-overview.component";
import { MachinesDetailComponent } from "./pages/machines-detail/machines-detail.component";
import { MachinesDownloadsComponent } from "./pages/machines-downloads/machines-downloads.component";
import { MachinesModelComponent } from "./pages/machines-model/machines-model.component";
import { CustomerIdGuard } from "../../core/guards/customerId.guard";


const routes: Routes = [
  {
    path: '',
    component: MachinesComponent,
    data: {
      breadcrumb: 'Machine'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview'
      },
      {
        path: 'overview',
        component: MachinesOverviewComponent,
        data: {
          breadcrumb: 'Overview'
        }
      },
    ]
  },
  {
    path: "detail/:id",
    component: MachinesDetailComponent,
    canActivate: [CustomerIdGuard],
    data: {
      breadcrumb: 'Detail'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'downloads'
      },
      {
        path: 'downloads',
        component: MachinesDownloadsComponent,
        data: {
          breadcrumb: 'Downloads'
        }
      },
      {
        path: 'models',
        component: MachinesModelComponent,
        data: {
          breadcrumb: 'Models'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MachinesRoutingModule {
}

import { Component } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../../../services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { CustomerDetailService } from "../../services/customers-detail.service";

type CustomerDetail = {
  customerId: number;
  customer: string;
  mate: string;
  email: string;
  city: string;
  street: string;
  state: string;
  zip: string;
  customerNumber: string;
};

@Component({
  selector: 'app-customers-admin',
  templateUrl: './customers-admin.component.html',
})
export class CustomersAdminComponent {
  customer: CustomerDetail | undefined;
  customerId: string | null = "";

  constructor(private api: ApiService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute, private customerDetailService: CustomerDetailService) {

  }

  ngOnInit() {
    this.route.parent?.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      if (this.customerId) {
        this.api.get<CustomerDetail[]>(`portal/customer/${this.customerId}`).subscribe({
          next: data => {
            this.customer = data[0];
            this.customerDetailService.setCustomerDetail(this.customer);
          },
          error: error => {
            console.error('Fehler beim Abrufen der Customerdetails:', error);
            this.toastr.error('Fehler beim Abrufen der Customerdetails:', error)
          }
        });
      }
    });
  }

  deleteCustomer(customer: any) {
    this.api.delete(`portal/customer/${customer.customerId}`).subscribe(
      {
        next: data => {
          this.router.navigate(['/customers']);
          this.toastr.success(`customer ${customer.customer} deleted successfully`)
        },
        error: error => {
          console.error(`Error deleting user with ID ${customer.customerId}:`, error);
          this.toastr.error(`Error deleting customer ${customer.customer}:`)
        }
      });
  }

  onSubmit(formData: any) {
    this.api.patch<CustomerDetail>(`portal/customer/${this.customerId}`, formData
    ).subscribe()
    this.router.navigate(['/customers']);
    this.toastr.success("Customer was successfully saved")
  }

}

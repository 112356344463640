import { Component, AfterViewInit, computed } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { PageTitleService } from "../../shared/services/page-title.service";
import { DarkmodeService } from "../../core/services/darkmode.service";

declare var grecaptcha: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  email: string = '';
  message: string = '';
  signedIn: boolean = false;
  isDarkMode = computed(() => this.darkModeService.darkMode());
  constructor(private authService: AuthService, private pageTitleService: PageTitleService, private darkModeService: DarkmodeService) {
    this.pageTitleService.setTitle("Login")
  }

  onSubmit(): void {
    grecaptcha.ready(() => {
      grecaptcha.execute('6LdDv-spAAAAAEid1mlxIeolhdcf6QD6GbFMAH4B', { action: 'submit' }).then((token: string) => {
        this.handleSubmit(token);
      });
    });
  }

  handleSubmit(token: any): void {
    this.authService.requestMagicLink(this.email).subscribe({
      next: (response: { message: string }) => {
        this.signedIn = true;
        this.message = response.message;
      },
      error: (error: { message: string }) => {
        this.message = "An error occurred. Please try again.";
      }
    });
  }
}

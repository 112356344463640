<div *ngIf="filteredMachines.length > 0; else waitForResult">
  <app-search-bar (searchValue)="onFilterTextBoxChanged($event)" class="block mb-3"></app-search-bar>

  <div class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
    <div *ngFor="let machine of filteredMachines  | filter: currentFilter" [routerLink]="['/machines/detail/', machine.machineId]"
         class="cursor-pointer">

      <div class="bg-white border border-gray-200 rounded-lg shadow dark:bg-zinc-800">
        @if (machine.image) {
          <img class="rounded-t-lg !w-full h-[250px] object-cover" src="{{machine.image}}"
               alt="{{ machine.machineName }}"/>
        }
        <div class="p-5">
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">{{ machine.machineName }}</h5>
          <p class="mb-3 font-normal text-gray-700">{{ machine.machineCity }}</p>
          <p class="font-normal text-gray-700">{{ machine.machineTag }}</p>
        </div>
      </div>

    </div>
  </div>

</div>

<ng-template #waitForResult>
  <div class='w-full min-h-16 mt-12'>
    <div class='h-1.5 w-full bg-blue-100 overflow-hidden'>
      <div class='animate-progress w-full h-full bg-blue-500 origin-left-right'></div>
    </div>
  </div>
</ng-template>



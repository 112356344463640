// login-callback.component.ts

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { ApiService } from "../../services/api.service";

interface AccessTokenResponse {
  access_token: string;
}

@Component({
  template: `<!-- Ihr Template hier -->`,
})
export class LoginCallbackComponent implements OnInit {

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      if (token) {
        this.api.get(`auth/login/callback?token=${token}`).subscribe({
          next: (response: any) => { // Verwenden Sie 'any' oder einen spezifischeren Typ, wenn verfügbar
            const tokenResponse = response as AccessTokenResponse; // Typassertion hier
            this.authService.saveAccessToken(tokenResponse.access_token);
            this.authService.saveToken(token);

            // Weiterleitung zum geschützten Bereich der Anwendung
            this.router.navigate(['/']).then(() => {
              window.location.reload(); // Seite neu laden
            });
          },
          error: (error) => {
            this.router.navigate(['/auth/login'], {queryParams: {error: 'Token is missing'}});
          }
        });
      } else {
        // Fehlerbehandlung, wenn kein Token vorhanden ist
        this.router.navigate(['/auth/login'], {queryParams: {error: 'Token is missing'}});
      }
    });
  }


}

import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
})
export class SearchBarComponent {
  @Output() searchValue: EventEmitter<string> = new EventEmitter();

  searchString: string = ""

  filter(): void {
    this.searchValue.emit(this.searchString);
  }
}

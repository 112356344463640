import { Component } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { Title } from "@angular/platform-browser";
import { PageTitleService } from "../../../../shared/services/page-title.service";

type Customer = {
  customerId: number
  kunde: string;
  ansprechpartner: string;
  email: string;
  standort: string;
  street: string;
  ort: string;
  state: string;
  zip: string;
  customerNumber: string;
}

@Component({
  selector: 'app-customer',
  templateUrl: './customers-overview.component.html',
})
export class CustomersOverviewComponent {
  customers: Customer[] = [];
  currentFilter: string = "";

  constructor(private api: ApiService, private pageTitleService: PageTitleService) {
    this.pageTitleService.setTitle("Customers")
  }

  ngOnInit() {
    this.api.get<Customer[]>('portal/customer').subscribe(data => {
      this.customers = data;
    })
  }

  onFilterTextBoxChanged(filterValue: string) {
    this.currentFilter = filterValue;
  }


}

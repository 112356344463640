import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthModule } from "./auth/auth.module";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { CustomersModule } from "./features/customers/customers.module";
import { MachinesModule } from "./features/machines/machines.module";
import { ContactModule } from "./features/contact/contact.module";
import { ForbiddenModule } from "./features/forbidden/forbidden.module";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    CustomersModule,
    MachinesModule,
    ContactModule,
    ForbiddenModule,
    AuthModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      closeButton: false,
      newestOnTop: false,
      easing: 'ease-in',
      easeTime: 500,
      progressBar: true,
      progressAnimation: "increasing",
      maxOpened: 5,
      autoDismiss: true,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailService {
  private customerDetailSource = new BehaviorSubject<any>(null);
  customerDetail$ = this.customerDetailSource.asObservable();

  setCustomerDetail(customerDetail: any) {
    this.customerDetailSource.next(customerDetail);
  }
}

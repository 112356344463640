import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface LinkState {
  link: string;
  text: string;
  visible: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LinkStateService {
  private backLinkSource = new BehaviorSubject<LinkState>({
    link: '/',
    text: 'Back',
    visible: false
  });
  backLink$ = this.backLinkSource.asObservable();

  setBackLink(state: LinkState) {
    setTimeout(() => {
      this.backLinkSource.next(state);
    }, 0);
  }
}

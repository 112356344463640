import { Component } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CustomersMachinesService } from "../../services/customers-machines.service";
import { Machine } from "../../types/machine.type";
import { Asset } from "../../types/asset.type";
import { HttpEvent, HttpEventType } from "@angular/common/http";

@Component({
  selector: 'app-customers-machines',
  templateUrl: './customers-machines.component.html',
})
export class CustomersMachinesComponent {
  machine: Machine | undefined;
  machines: Machine[] | undefined;
  assets: Asset[] | undefined;
  selectedMachine: Machine | undefined;
  currentFilter: string = "";
  customerId: number | null = null;

  openModal = false;
  isAddDownloadlink = false;
  assetImage = false;
  isAddImage = false;
  isAddModel = false;
  assetModel = false;
  assetsToDelete: number[] = [];
  selectedFiles: { [key: string]: File | File[] } = {};
  isLoading = true;
  uploadProgress: number = 0;
  remainingTime: string = "";

  private uploadStartTime: number = 0;
  private lastUploadedBytes: number = 0;
  private lastTimeStamp: number = 0;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private customersMachinesService: CustomersMachinesService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.route.parent?.paramMap.subscribe(params => {
      const id = params.get('id');
      this.customerId = id ? +id : null;
      if (this.customerId) {
        this.customersMachinesService.getMachines(this.customerId);
        this.customersMachinesService.machines$.subscribe(machines => {
          this.machines = machines;
        });
      }
    });
  }

  editMachine(machine: any) {
    this.selectedMachine = { ...machine };

    const machineId = this.selectedMachine?.machineId;

    if (machineId) {
      this.customersMachinesService.getAssets(machineId).subscribe({
        next: data => {
          this.assets = data;

          // Kategorien verarbeiten
          this.assets.forEach(asset => {
            if (asset.assetCategory === 'Image') {
              this.assetImage = true;
            }
            if (asset.assetCategory === 'Model') {
              this.assetModel = true;
            }
          });
        },
        error: error => {
          this.toastr.error('Error retrieving assets:', error);
        }
      });
    }

    this.openModal = true;
  }

  onFileSelected(event: any, type: string) {
    if (type === 'downloadlinks') {
      this.selectedFiles[type] = Array.from(event.target.files);
    } else {
      this.selectedFiles[type] = event.target.files[0];
    }
  }

  markAssetForDeletion(assetId: number) {
    const asset = this.assets?.find(a => a.assetId === assetId);
    if (asset) {
      asset.markedForDeletion = true;
      this.assetsToDelete.push(assetId);
    }
  }

  cancelDeletions() {
    this.assets?.forEach(asset => {
      asset.markedForDeletion = false;
    });
    this.assetsToDelete = [];
    this.openModal = false;
  }

  addMachine() {
    this.openModal = true;

    const newMachine: Machine = {
      customerId: this.customerId ?? 0,
      machineId: this.generateRandomId(),
      machineName: '',
      machineCity: '',
      machineTag: '',
      editable: true,
    };
    this.selectedMachine = newMachine;

    if (Array.isArray(this.machines)) {
      this.machines.push(newMachine);
    } else {
      this.machines = [newMachine];
    }
  }

  saveMachine(machine: any) {
    const formDataAssets = new FormData();
    if (this.selectedFiles['image'] && this.selectedFiles['image'] instanceof File) {
      formDataAssets.append('image', this.selectedFiles['image']);
    }
    if (this.selectedFiles['model'] && this.selectedFiles['model'] instanceof File) {
      formDataAssets.append('model', this.selectedFiles['model']);
    }

    if (this.selectedFiles['downloadlinks'] && Array.isArray(this.selectedFiles['downloadlinks'])) {
      this.selectedFiles['downloadlinks'].forEach((file, index) => {
        if (file instanceof File) {
          formDataAssets.append(`downloadlinks[${index}]`, file);
        }
      });
    }

    this.assetsToDelete.forEach(assetId => {
      this.deleteAsset(assetId);
    });

    if (this.customerId) {
      this.customersMachinesService.saveMachine(this.customerId, machine).subscribe({
        next: () => {
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = true;
        }
      });
    }

    this.uploadProgress = 0;
    this.uploadStartTime = Date.now();
    this.lastUploadedBytes = 0;
    this.lastTimeStamp = Date.now();

    this.customersMachinesService.updateAsset(machine.machineId, machine.machineName, formDataAssets).subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round((100 * event.loaded) / event.total!);
          this.uploadProgress = progress;

          const currentTime = Date.now();
          const elapsedTime = (currentTime - this.lastTimeStamp) / 1000; // in seconds
          const uploadedBytes = event.loaded - this.lastUploadedBytes;
          const uploadSpeed = uploadedBytes / elapsedTime; // bytes per second

          const remainingBytes = event.total! - event.loaded;
          const remainingTimeSeconds = remainingBytes / uploadSpeed;

          this.remainingTime = this.formatTime(remainingTimeSeconds);

          this.lastUploadedBytes = event.loaded;
          this.lastTimeStamp = currentTime;
        } else if (event.type === HttpEventType.Response) {
          this.uploadProgress = 100;
          this.toastr.success(`Machine ${machine.machineName} successfully saved on server`);
          window.location.reload(); // Reload the page when the upload to the server is complete
        }
      },
      error: () => {
        this.toastr.error('Error uploading files');
      }
    });

    this.assetsToDelete = [];
  }

  private formatTime(seconds: number): string {
    const roundedSeconds = Math.ceil(seconds / 10) * 10; // Runden auf nächste 10 Sekunden
    const mins = Math.floor(roundedSeconds / 60);
    const secs = Math.floor(roundedSeconds % 60);
    return `${mins}m ${secs}s`;
  }

  deleteMachine(machine: any) {
    this.customersMachinesService.deleteMachine(machine.machineId, machine.machineName).subscribe();
    this.customersMachinesService.deleteAsset(machine.machineId, machine.machineName).subscribe();
  }

  deleteAsset(assetId: number) {
    this.customersMachinesService.deleteAssetSingle(assetId).subscribe();
  }

  generateRandomId = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const num = array[0] % 900000; // Reduzieren auf den Bereich 0-899999
    return num + 100000;
  };

  onFilterTextBoxChanged(filterValue: string) {
    this.currentFilter = filterValue;
  }
}

import { Component } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";


type User = {
  userId?: number;
  customerId: number;
  name: string;
  surname: string;
  email: string;
  role: string;
  editable: boolean;
};

@Component({
  selector: 'app-customers-users',
  templateUrl: './customers-users.component.html',
})
export class CustomersUsersComponent {
  users: User[] = []; // Benutzerliste
  customerId: number | null = null;
  currentFilter: string = "";

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.parent?.paramMap.subscribe(params => {
      const id = params.get('id');
      this.customerId = id ? +id : null;
      if (this.customerId) {
        this.loadUsers();
      }
    });
  }

  loadUsers() {
    this.api.get<User[]>(`portal/customer/${this.customerId}/users`).subscribe({
      next: data => {
        this.users = data;
      },
      error: error => {
        console.error('Fehler beim Abrufen der Userdetails:', error);
        this.toastr.error('Fehler beim Abrufen der Userdetails:', error);
      }
    });
  }

  editUser(user: User) {
    user.editable = !user.editable;
  }

  saveUser(user: User) {
      this.api.post<User>(`portal/user/${this.customerId}`, user).subscribe({
        next: data => {
          console.log('Neuer Benutzer wurde hinzugefügt:', data);
          this.toastr.success("User was successfully added");
          this.loadUsers(); // Benutzerliste neu laden
        },
        error: error => {
          console.error('Fehler beim Hinzufügen des neuen Benutzers:', error);
          this.toastr.error('Fehler beim Hinzufügen des neuen Benutzers');
        }
      });

  }

  addUser() {
    const newUser: User = {
      customerId: this.customerId ?? 0,
      name: '',
      surname: '',
      email: '',
      role: 'user',
      editable: true
    };

    this.users.push(newUser);
  }

  deleteUser(user: User) {
    this.api.delete(`portal/user/${user.userId}`).subscribe({
      next: () => {
        this.toastr.success(`User ${user.name} deleted successfully`);
        this.loadUsers(); // Benutzerliste neu laden
      },
      error: error => {
        console.error(`Error deleting user ${user.name}:`, error);
        this.toastr.error(`Error deleting user ${user.name}:`, error);
      }
    });
  }

  onFilterTextBoxChanged(filterValue: string) {
    this.currentFilter = filterValue;
  }
}


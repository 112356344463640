<div class="flex items-center justify-center h-screen">
  <div class="flex flex-col justify-center w-1/2 h-full py-4 lg:py-8 px-4 lg:px-8 mx-auto bg-white rounded-2xl shadow-xl">
    <button class="submitButton absolute top-5 left-8"  [routerLink]="['/auth/login/']">Back</button>

    <div>
      <h1 class="mt-2 text-1xl font-semibold text-gray-800 md:text-2xl mb-2">Instructions for Accessing Our Platform</h1>
      <p>Thank you for your interest in accessing our platform. To ensure a smooth registration process, please carefully follow the steps outlined below:</p>
    </div>


    <div class="mt-12">

      <h2 class="mb-2 text-xl font-semibold text-gray-900">Fill out the Required Information:</h2>
      <ul class="space-y-1 text-gray-500 list-disc list-inside">
        <li>
          <span class="font-bold">Name:</span> Please enter your full name in the designated field. This will help us address you properly and personalize your experience.
        </li>
        <li>
          <span class="font-bold">Email:</span> Provide a valid email address that you regularly use. We will use this email to communicate with you regarding your registration and account details.
        </li>
        <li>
          <span class="font-bold">Company:</span> Provide the name of your company. This information helps us understand our user demographics better.
        </li>
      </ul>
    </div>

    <div class="mt-6">
      <h2 class="mb-2 text-xl font-semibold text-gray-900">Submission:</h2>
      <ul class="space-y-1 text-gray-500 list-disc list-inside">
        <li>
          Once you have entered all the required information, click on the "Send" button. This action will send your details to our team for review.
        </li>
      </ul>
    </div>

    <div class="mt-6">
      <h2 class="mb-2 text-xl font-semibold text-gray-900">Review Process:</h2>
      <ul class="space-y-1 text-gray-500 list-disc list-inside">
        <li>
          Our team will carefully review the information you have provided. We ensure the accuracy and validity of the details to maintain the integrity of our platform.
        </li>
      </ul>
    </div>

    <div class="mt-6">
      <h2 class="mb-2 text-xl font-semibold text-gray-900">Confirmation Email:</h2>
      <ul class="space-y-1 text-gray-500 list-disc list-inside">
        <li>
          If your submitted information meets our criteria and passes our verification process, you will receive a confirmation email from us.
        </li>
      </ul>
    </div>
  </div>

  <div class="w-1/2 h-full py-4 lg:py-8 px-4 lg:px-8 mx-auto flex flex-col justify-center">
      <form (ngSubmit)="sendEmail()" [formGroup]="registerForm" class="space-y-8" novalidate>
        <div>
          <label class="inputLabel" for="name">Your Name</label>
          <input class="input" formControlName="name" id="name" placeholder="Max Mustermann" required type="text">
        </div>
        <div>
          <label class="inputLabel" for="email">Your email</label>
          <input class="input" formControlName="email" id="email" placeholder="example@pcagmbh.de" required
                 type="email">
        </div>
        <div>
          <label class="inputLabel" for="company">Your Company</label>
          <input class="input" formControlName="company" id="company" placeholder="Company" required
                 type="email">
        </div>

        <button class="submitButton" type="submit">Send message</button>
      </form>
  </div>
</div>

<a (click)="addMachine()" class="absolute cursor-pointer right-14 bottom-6 flex px-8 py-2 font-bold bg-blue-50 hover:bg-blue-100 text-green-400 rounded-lg text-sm dark:bg-zinc-700 dark:hover:bg-zinc-900"
   type="button">
  <span class="text-4xl material-symbols-outlined">add</span>
</a>

<app-search-bar (searchValue)="onFilterTextBoxChanged($event)" class="block mb-3"></app-search-bar>

<div class="w-full">
  <!-- Akkordeon Header, ähnlich wie thead -->
  <div class="flex border-b border-collapse text-slate-500">
    <div class="basis-4/12 accordionHeader">
      Name
    </div>
    <div class="basis-3/12 accordionHeader">
      Place
    </div>
    <div class="basis-3/12 accordionHeader">
      Tag
    </div>
    <div class="basis-2/12 accordionHeader">

    </div>
  </div>

  <form #machineForm="ngForm" enctype="multipart/form-data" class="h-[910px] overflow-y-auto">
    <div class="divide-y divide-gray-200">
      <div *ngFor="let machine of machines | filter: currentFilter; let i = index"
           [ngClass]="{
        'bg-gray-50': i % 2 === 0,
        'bg-white': i % 2 !== 0,
        'dark:bg-gray-800': i % 2 === 0,
        'dark:bg-gray-700': i % 2 !== 0
     }"
           class="accordion-item">

        <div (click)="editMachine(machine)" class="flex items-center cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-900">
          <!-- Maschinenname -->
          <div class="p-3 text-left align-middle bg-transparent whitespace-nowrap shadow-transparent basis-4/12">
            <span class="px-2 py-1">{{ machine.machineName }}</span>
          </div>

          <!-- Maschinenstadt -->
          <div class="p-3 text-left align-middle bg-transparent whitespace-nowrap shadow-transparent basis-3/12">
            <span class="px-2 py-1">{{ machine.machineCity }}</span>
          </div>

          <!-- Maschinen Tag -->
          <div class="p-3 text-left align-middle bg-transparent whitespace-nowrap shadow-transparent basis-3/12">
            <span class="px-2 py-1">{{ machine.machineTag }}</span>
          </div>

          <!-- Bearbeiten/Speichern Knopf -->
          <div class="pr-6 text-end align-middle bg-transparent whitespace-nowrap shadow-transparent basis-2/12">
                  <span (click)="editMachine(machine)" *ngIf="!machine.editable"
                        class="inline-flex p-1 mr-2 text-blue-400 rounded bg-blue-50 hover:bg-blue-100 dark:bg-zinc-700 dark:hover:bg-zinc-900">
                      <ng-icon class="relative top-0 leading-normal text-blue-400 ni ni-tv-2 text-2xl"
                               name="matEditOutline"/>
                  </span>
            <span (click)="deleteMachine(machine)"
                  class="inline-flex p-1 text-blue-400 rounded bg-blue-50 hover:bg-blue-100 dark:bg-zinc-700 dark:hover:bg-zinc-900">
          <ng-icon class="relative top-0 leading-normal text-blue-400 ni ni-tv-2 text-2xl" name="matDeleteOutline"/>
          </span>
          </div>
        </div>

        <!-- Modal -->
        <div *ngIf="openModal" class="fixed z-[999] inset-0 bg-black bg-opacity-20 flex justify-center items-center">
          <div class="bg-white rounded-lg shadow-lg m-4 min-w-[1000px] max-w-full max-h-full overflow-y-auto dark:bg-zinc-800">
            <div class="p-6">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                <div class="sm:col-span-3">
                  <label class="inputLabel" for="machineName">Machine Name</label>
                  <div class="mt-2">
                    <input *ngIf="selectedMachine" [(ngModel)]="selectedMachine.machineName" class="input"
                           id="machineName" name="machineName" placeholder="Name" type="text"/>
                  </div>
                </div>

                <div class="sm:col-span-3">
                  <label class="inputLabel" for="machineCity">Machine place</label>
                  <div class="mt-2">
                    <input *ngIf="selectedMachine" [(ngModel)]="selectedMachine.machineCity" class="input"
                           id="machineCity" name="machineCity" placeholder="Place" type="text"/>
                  </div>
                </div>

              </div>

              <div class="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                <div class="sm:col-span-3">
                  <label class="inputLabel" for="machineTag">Machine Tag</label>
                  <div class="mt-2">
                    <input *ngIf="selectedMachine" [(ngModel)]="selectedMachine.machineTag" class="input"
                           id="machineTag" name="machineTag" placeholder="Place" type="text"/>
                  </div>
                </div>

              </div>
            </div>

            <div class="modalBorder">
              <div class="modalFlex">
                Image

                <a (click)="this.isAddImage = true" *ngIf="!assetImage" class="modalLink" type="button">
                  <span class="text-md material-symbols-outlined">add</span>
                </a>

              </div>
            </div>

            @for (asset of assets; track asset) {
              @if (asset.assetCategory === "Image") {
                <div *ngIf="!asset.markedForDeletion"
                     class="items-center justify-between flex hover:bg-gray-50 cursor-pointer">
                  <div class="p-3 text-left align-middle bg-transparent whitespace-nowrap shadow-transparent">
                    <span class="px-2 py-1">{{ asset.assetOriginalName }}</span>
                  </div>
                  <span *ngIf="selectedMachine"
                        class="inline-flex p-1 m-3 mr-6 text-blue-500 rounded bg-blue-50 hover:bg-blue-100">
                  <ng-icon (click)="markAssetForDeletion(asset.assetId)"
                           class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl"
                           name='matDeleteOutline'/>
                </span>
                </div>
              }
            }
            <input (change)="onFileSelected($event, 'image')" *ngIf="this.isAddImage" class="fileInput" type="file">


            <div class="modalBorder">
              <div class="modalFlex">
                3D Model

                <a (click)="this.isAddModel = true" *ngIf="!assetModel" class="modalLink" type="button">
                  <span class="text-md material-symbols-outlined">add</span>
                </a>

              </div>
            </div>

            @for (asset of assets; track asset) {
              @if (asset.assetCategory === "Model") {
                <div *ngIf="!asset.markedForDeletion"
                     class="items-center justify-between flex hover:bg-gray-50 cursor-pointer">
                  <div class="p-3 text-left align-middle bg-transparent whitespace-nowrap shadow-transparent">
                    <span class="px-2 py-1">{{ asset.assetOriginalName }}</span>
                  </div>
                  <span *ngIf="selectedMachine"
                        class="inline-flex p-1 m-3 mr-6 text-blue-500 rounded bg-blue-50 hover:bg-blue-100">
                  <ng-icon (click)="markAssetForDeletion(asset.assetId)"
                           class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl"
                           name='matDeleteOutline'/>
                </span>
                </div>
              }
            }
            <input (change)="onFileSelected($event, 'model')" *ngIf="this.isAddModel" class="fileInput" type="file">


            <div class="modalBorder">
              <div class="modalFlex">
                Operating manual

                <a (click)="this.isAddDownloadlink = true" class="modalLink" type="button">
                  <span class="text-md material-symbols-outlined">add</span>
                </a>

              </div>
            </div>

            @for (asset of assets; track asset) {
              @if (asset.assetCategory === "Downloadlink") {
                <div *ngIf="!asset.markedForDeletion"
                     class="items-center justify-between flex hover:bg-gray-50 cursor-pointer">
                  <div class="p-3 text-left align-middle bg-transparent whitespace-nowrap shadow-transparent">
                    <span class="px-2 py-1">{{ asset.assetOriginalName }}</span>
                  </div>
                  <span *ngIf="selectedMachine"
                        class="inline-flex p-1 m-3 mr-6 text-blue-500 rounded bg-blue-50 hover:bg-blue-100">
                  <ng-icon (click)="markAssetForDeletion(asset.assetId)"
                           class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl"
                           name='matDeleteOutline'/>
                </span>
                </div>
              }
            }

            <input (change)="onFileSelected($event, 'downloadlinks')" *ngIf="this.isAddDownloadlink" accept=".zip" class="fileInput"
                   type="file">

            <div class="flex justify-end m-6 mt-12">
              <button (click)="cancelDeletions()" class="deleteButton mr-2" type="submit">Cancel</button>
              <button (click)="saveMachine(selectedMachine)" class="saveButton" type="submit">Save</button>
            </div>

            <div *ngIf="uploadProgress < 100 && uploadProgress > 0" class='w-full min-h-16 mt-12'>
              <div class='h-1.5 w-full bg-blue-100 overflow-hidden'>
                <div class='w-full h-full bg-blue-500 origin-left-right' [style.width.%]="uploadProgress"></div>
              </div>
              <p class="text-center mt-2">{{uploadProgress}}%</p>
              <div class="text-center mt-2 mb-2">Remaining Time: {{ remainingTime }}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>


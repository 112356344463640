import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map, Observable } from "rxjs";
import { MachineCustomerIdService } from "../services/machineCustomerId.service";

@Injectable({
  providedIn: 'root'
})
export class CustomerIdGuard {
  canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean => {
    const user = this.authService.getUserRole();
    if (user === 'Administrator') {
      return true;
    } else {
      const machineId = +route.params['id'];

      return this.machineCustomerId.getMachineById(machineId).pipe(
        map(machine => {
          const machineCustomerIdAsString = Number(machine.customerId);
          const userCustomerIdAsString = Number(this.authService.getCustomerId());

          if (machineCustomerIdAsString === userCustomerIdAsString) {
            return true;
          } else {
            this.router.navigate(['/forbidden/overview']);
            return false;
          }
        })
      );
    }
  };

  constructor(private authService: AuthService, private router: Router, private machineCustomerId: MachineCustomerIdService) {
  }
}

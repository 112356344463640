import { Component } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NavItem } from "../../../../shared/components/tab-nav/nav-item.type";
import { Subscription } from "rxjs";
import { LinkStateService } from "../../../../shared/services/link-state.service";
import { PageTitleService } from "../../../../shared/services/page-title.service";

@Component({
  selector: 'app-machines-detail',
  templateUrl: './machines-detail.component.html',
})
export class MachinesDetailComponent {

  id!: string | null
  navigation: NavItem[] = [
    {
      title: 'Downloads',
      route: 'downloads'
    },
    {
      title: '3d Model',
      route: 'models'
    },
  ];
  private subscriptions = new Subscription();

  constructor(private route: ActivatedRoute, private linkState: LinkStateService, private pageTitleService: PageTitleService,) {
  }


  ngOnInit() {
    this.subscriptions.add(
      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
        this.pageTitleService.setTitle(`Machine - ${this.id}`);
        this.linkState.setBackLink({
          link: '/machines',
          text: 'Back to overview',
          visible: true
        });
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.linkState.setBackLink({
      link: '',
      text: '',
      visible: false
    });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean => {
    const user = this.authService.getUserRole();
    if (user === 'Administrator') {
      return true;
    }
    this.router.navigate(['/forbidden/overview']);
    return false;
  };

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) {
  }
}

import { Component } from '@angular/core';
import { AutologoutService } from '../../services/autologout.service';
import { map, Observable } from "rxjs";

@Component({
  selector: 'app-logoutTimer',
  template: `<span *ngIf="remainingTime$ | async as time">{{ time }}</span>`,
})
export class LogoutTimerComponent {
  remainingTime$: Observable<string>;


  constructor(private autologoutService: AutologoutService) {
    this.remainingTime$ = this.autologoutService.getRemainingTime().pipe(
      map(milliseconds => {
        const hours = Math.floor(milliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      })
    );
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenComponent } from "./pages/forbitten/forbidden.component";
import { ForbiddenOverviewComponent } from "./pages/forbitten-overview/forbidden-overview.component";
import { ForbiddenDetailComponent } from "./pages/forbitten-detail/forbidden-detail.component";

const routes: Routes = [
  {
    path: '',
    component: ForbiddenComponent,
    data: {
      breadcrumb: 'Forbidden'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview'
      },
      {
        path: 'overview',
        component: ForbiddenOverviewComponent,
        data: {
          breadcrumb: 'Overview'
        }
      },
    ]
  },
  {
    path: "detail/:id",
    component: ForbiddenDetailComponent,
    data: {
      breadcrumb: 'Detail'
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ForbiddenRoutingModule {
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from "./pages/contact/contact.component";
import { ContactOverviewComponent } from "./pages/contact-overview/contact-overview.component";
import { ContactDetailComponent } from "./pages/contact-detail/contact-detail.component";

const routes: Routes = [
  {
    path: '',
    component: ContactComponent,
    data: {
      breadcrumb: 'Contact'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview'
      },
      {
        path: 'overview',
        component: ContactOverviewComponent,
        data: {
          breadcrumb: 'Overview'
        }
      },
    ]
  },
  {
    path: "detail/:id",
    component: ContactDetailComponent,
    data: {
      breadcrumb: 'Detail'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactRoutingModule {
}

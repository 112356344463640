import { Injectable } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { Router } from "@angular/router";
import { map, Observable } from "rxjs";

type Machine = {
  machineId: number,
  customerId: string,
  machineName: string,
  machineCity: string,
  machineTag: string,
  image?: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class MachineCustomerIdService {

  constructor(private api: ApiService, private router: Router) {

  }

  getMachineById(machineId: number): Observable<Machine> {
    return this.api.get<Machine[]>(`portal/machine/${machineId}`).pipe(
      map(machines => machines[0]) // Nimmt das erste Element des Arrays
    );
  }

}

import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AuthService } from "../../../../core/services/auth.service";
import { Asset } from "../../../customers/types/asset.type";
import { MachineDetail } from "../../types/machineDetail.type";
import { MachinesService } from "../../services/machines.service";

@Component({
  selector: 'app-machines-model',
  templateUrl: './machines-model.component.html',
})
export class MachinesModelComponent {
  load3D: boolean = false;
  machineId: string | null = "";
  customerId: string | null = null;
  machine: MachineDetail | undefined;
  assets: Asset[] | undefined;
  trustedUrl: SafeResourceUrl | undefined;
  modelAssetId: number | undefined;

  constructor(
    private machineService: MachinesService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.parent?.paramMap.subscribe(params => {
      this.machineId = params.get('id');
      this.customerId = this.authService.getCustomerId();
      const userRole = this.authService.getUserRole();
      if (this.machineId) {
        this.machineService.getMachineDetails(this.machineId, userRole).subscribe({
          next: data => {
            this.machine = data[0];
          },
          error: error => console.error('Error retrieving machine details:', error)
        });
        this.machineService.getMachineAssets(this.machineId).subscribe({
          next: data => {
            this.assets = data;
            this.modelAssetId = this.assets.find(asset => asset.assetCategory === 'Model')?.assetId;
          },
          error: error => {
            console.error('Error retrieving assets:', error);
          }
        });
      }
    });
  }

  load3DModel() {
    if (this.modelAssetId !== undefined) {
      this.machineService.getSignedUrl(this.modelAssetId).subscribe({
        next: data => {
          this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.signedUrl);
          this.load3D = true;
        },
        error: error => {
          console.error('Error retrieving signed URL:', error);
        }
      });
    }
  }
}

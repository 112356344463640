import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginCallbackComponent } from "./callback/login-callback.component";
import { LoginRegisterComponent } from "./register/login-register.component";

const routes: Routes = [
  {
    path: 'auth/login',
    data: {
      breadcrumb: 'Login'
    },
    children: [
      {
        path: '',
        component: LoginComponent,
        pathMatch: 'full',
      },
      {
        path: 'callback',
        component: LoginCallbackComponent,
        pathMatch: 'full',
      },
      {
        path: 'register',
        component: LoginRegisterComponent,
        pathMatch: 'full',
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SharedModule } from "../shared/shared.module";
import { LogoutTimerComponent } from "./components/logoutTimer/logoutTimer.component";
import { DropdownDirective } from "../shared/directives/dropdown.directive";


@NgModule({
  declarations: [
    SidebarComponent,
    LogoutTimerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DropdownDirective,
    NgOptimizedImage
  ],
  exports: [
    SidebarComponent,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only.');
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, map, Observable, takeWhile } from 'rxjs';
import { jwtDecode } from "jwt-decode";
import { AuthService } from "./auth.service";

interface DecodedToken {
  role: string;
  adminExp?: number;
  userExp: number;
}

@Injectable({
  providedIn: 'root'
})
export class AutologoutService {
  customerId: string = "";

  private remainingTime$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private authService: AuthService) {
    this.initializeAutoLogout();
  }

  getRemainingTime(): Observable<number> {
    return this.remainingTime$.asObservable();
  }

  private initializeAutoLogout() {
    const token = localStorage.getItem('auth_accesstoken');
    if (token) {
      const decodedToken = jwtDecode<DecodedToken>(token);
      const expirationTime = decodedToken.role === 'Administrator' && decodedToken.adminExp
        ? decodedToken.adminExp * 1000
        : decodedToken.userExp * 1000;
      const remainingTime = expirationTime - Date.now();

      if (remainingTime > 0) {
        interval(1000).pipe(
          takeWhile(() => remainingTime > 0),
          map(() => {
            const updatedTime = expirationTime - Date.now();
            this.remainingTime$.next(updatedTime);
            return updatedTime;
          }),
          takeWhile(updatedTime => updatedTime > 0)
        ).subscribe({
          complete: () => this.authService.logout()
        });
      } else {
        this.authService.logout();
      }
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { ApiService } from "../../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { Machine } from "../types/machine.type";
import { Asset } from "../types/asset.type";


@Injectable({providedIn: 'root'})
export class CustomersMachinesService {
  private machines = new BehaviorSubject<Machine[]>([]);
  public machines$ = this.machines.asObservable();

  constructor(private api: ApiService, private http: HttpClient, private toastr: ToastrService) {
  }

  getMachines(customerId: number) {
    this.api.get<Machine[]>(`portal/customer/${customerId}/machines`).subscribe({
      next: data => {
        this.machines.next(data);
      },
      error: error => {
        console.error('Fehler beim Abrufen der Machinedetails:', error);
        this.toastr.error('Fehler beim Abrufen der Machinedetails:', error);
      }
    });
  }

  getAssets(machineId: number): Observable<Asset[]> {
    return this.api.get<Asset[]>(`portal/asset/${machineId}`);
  }

  saveMachine(customerId: number, machine: any) {
    if (machine.editable) {
      return this.api.post<Machine>(`portal/machine/${customerId}`, machine).pipe(
        tap({
          next: (data) => {
            console.log('New machine has been added:', data);
          },
          error: (error) => {
            console.error('\n' +
              'Error adding the new machine:', error);
            this.toastr.error(`Error saving new machine: ${error.message}`);
          }
        })
      );
    } else {
      const updateData = {
        machineName: machine.machineName,
        machineCity: machine.machineCity,
        machineTag: machine.machineTag,
      };
      return this.api.patch<Machine>(`portal/machine/${machine.machineId}`, updateData).pipe(
        tap({
          next: () => {
            console.log('Maschine aktualisiert');
          },
          error: (error) => {
            console.error('Fehler beim Aktualisieren der Maschine:', error);
            this.toastr.error(`Fehler beim Aktualisieren der Maschine: ${machine.machineName}`);
          }
        })
      );
    }
  }

  updateAsset(machineId: number, machineName: string, formDataAssets: any): Observable<any> {
    return this.http.patch<Asset>(`https://backend.docs.pcagmbh.de/portal/asset/${machineId}`, formDataAssets, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      tap({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round((100 * event.loaded) / event.total!);
          } else if (event.type === HttpEventType.Response) {
            this.toastr.success(`Machine ${machineName} successfully saved`);
            window.location.reload();
          }
        },
        error: (error) => {
          console.error(`Error saving Assets: ${error.message}`, error.response);
          this.toastr.error(`Error saving Assets ${machineName}`);
        }
      }),
      catchError(error => throwError(() => new Error(error)))
    );
  }


  deleteMachine(machineId: number, machineName: string): Observable<any> {
    return this.api.delete(`portal/machine/${machineId}`).pipe(
      tap({
        next: () => {
          window.location.reload();
          this.toastr.success(`Machine ${machineName} deleted successfully`);
        },
        error: (error) => {
          console.error(`Error deleting machine ${machineName}:`, error);
          this.toastr.error(`Error deleting machine ${machineName}:`, error);
        }
      }),
      catchError(error => throwError(() => new Error(error)))
    );
  }

  deleteAsset(machineId: number, machineName: string): Observable<any> {
    return this.api.delete(`portal/asset/${machineId}`).pipe(
      tap({
        next: () => {
          this.toastr.success(`Machine ${machineName} deleted successfully`);
        },
        error: (error) => {
          console.error(`Error deleting machine ${machineName}:`, error);
          this.toastr.error(`Error deleting machine ${machineName}:`, error);
        }
      }),
      catchError(error => throwError(() => new Error(error)))
    );
  }

  deleteAssetSingle(assetId: number): Observable<any> {
    return this.api.delete(`portal/asset/delete/${assetId}`).pipe(
      tap({
        next: () => {
          this.toastr.success('Asset deleted successfully');
        },
        error: (error) => {
          console.error('Error deleting asset:', error);
          this.toastr.error('Error deleting asset');
        }
      }),
      catchError(error => throwError(() => new Error(error)))
    );
  }


}

import { Component } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { AuthService } from "../../../../core/services/auth.service";
import { Title } from "@angular/platform-browser";
import { Asset } from "../../../customers/types/asset.type";
import { MachinesService } from "../../services/machines.service";
import { forkJoin, map, switchMap } from "rxjs";
import { PageTitleService } from "../../../../shared/services/page-title.service";
import {CustomerDetail} from "../../../customers/types/customer.type";

type Machine = {
  machineId: number,
  customerId: string,
  machineName: string,
  machineCity: string,
  machineTag?: string,
  image?: string | null;
}


@Component({
  selector: 'app-machines-overview',
  templateUrl: './machines-overview.component.html',
})
export class MachinesOverviewComponent {
  viewMode: 'list' | 'grid' = 'grid'; // Standard ist Kachelansicht

  machines: Machine[] = [];
  filteredMachines: Machine[] = [];
  assets: Asset[] | undefined;
  currentFilter: any;
  groupedMachines: { [customerId: string]: Machine[] } = {};
  customers: CustomerDetail[] = [];
  userRole: string;

  constructor(private machineService: MachinesService, private api: ApiService, private authService: AuthService,  private pageTitleService: PageTitleService,) {
    this.pageTitleService.setTitle("Machines")
    this.userRole = this.authService.getUserRole();
  }

  ngOnInit() {
    // Lese die Ansicht aus dem Cache oder setze standardmäßig auf 'grid'
    const savedViewMode = localStorage.getItem('machinesViewMode');
    this.viewMode = savedViewMode ? savedViewMode as 'list' | 'grid' : 'grid';

    this.loadMachines();
  }

  toggleViewMode() {
    this.viewMode = this.viewMode === 'grid' ? 'list' : 'grid';
    localStorage.setItem('machinesViewMode', this.viewMode); // Speichert die Auswahl im Cache
  }

  onFilterTextBoxChanged(filter: string) {
    this.currentFilter = filter
  }

  private loadMachines() {
    const customerId: string = this.authService.getCustomerId();
    const userRole = this.authService.getUserRole();
    const baseUrl = userRole === 'Administrator' ? 'portal/machine' : `portal/machine/customer/${customerId}`;

    this.api.get<Machine[]>(baseUrl).pipe(
      switchMap(machines => {
        this.machines = machines;

        const assetRequests = machines.map(machine =>
          this.machineService.getMachineAssetsNumber(machine.machineId).pipe(
            map(assets => ({ machineId: machine.machineId, assets }))
          )
        );

        // Wenn der Benutzer ein Administrator ist, lade die Kundendetails
        if (userRole === 'Administrator') {
          const customerRequests = machines.map(machine =>
            this.machineService.getCustomerDetails(machine.customerId, userRole).pipe(
              map(customer => ({ customerId: machine.customerId, customerName: customer[0].customer })) // Annahme: customer[0] ist der Kunde
            )
          );
          return forkJoin([forkJoin(assetRequests), forkJoin(customerRequests)]);
        } else {
          // Nur die Asset-Anfragen ohne Kunden-Details
          return forkJoin([forkJoin(assetRequests)]);
        }
      }),
      map(([assetResponses, customerResponses]) => {
        // Verarbeite die Assets
        assetResponses.forEach(response => {
          const machineIndex = this.machines.findIndex(machine => machine.machineId === response.machineId);
          this.machines[machineIndex].image = response.assets.find(asset => asset.assetCategory === 'Image')?.assetPath ?? null;
        });

        // Wenn der Benutzer ein Administrator ist, verarbeite die Kundendetails
        if (userRole === 'Administrator' && customerResponses) {
          customerResponses.forEach(customerResponse => {
            this.machines.forEach(machine => {
              if (machine.customerId === customerResponse.customerId) {
                machine.customerId = customerResponse.customerName; // Ersetze die customerId durch den Namen
              }
            });
          });

          // Gruppiere Maschinen nach customerName
          this.groupedMachines = this.machines.reduce((acc, machine) => {
            if (!acc[machine.customerId]) {
              acc[machine.customerId] = [];
            }
            acc[machine.customerId].push(machine);
            return acc;
          }, {} as { [customerName: string]: Machine[] });
        } else {
          // Gruppiere Maschinen nach customerId für alle anderen Benutzer
          this.groupedMachines = this.machines.reduce((acc, machine) => {
            if (!acc[machine.customerId]) {
              acc[machine.customerId] = [];
            }
            acc[machine.customerId].push(machine);
            return acc;
          }, {} as { [customerId: string]: Machine[] });
        }

        return this.machines;
      })
    ).subscribe(
      {
        next: machines => {
          this.filteredMachines = userRole === 'Administrator' ? this.machines : this.machines.filter(machine => machine.customerId === customerId);
        },
        error: error => {
          console.error('Error retrieving machines or assets:', error)
        }
      });
  }


}

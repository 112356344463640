import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../../../core/services/auth.service";
import { Asset } from "../../../customers/types/asset.type";
import { MachineDetail } from "../../types/machineDetail.type";
import { MachinesService } from "../../services/machines.service";

@Component({
  selector: 'app-machines-downloads',
  templateUrl: './machines-downloads.component.html',
})
export class MachinesDownloadsComponent {
  machineId: string | null = "";
  customerId: string | null = null;
  machine: MachineDetail | undefined;
  assets: Asset[] | undefined;

  constructor(private route: ActivatedRoute, private authService: AuthService, private machineService: MachinesService) {}

  ngOnInit() {
    this.route.parent?.paramMap.subscribe(params => {
      this.machineId = params.get('id');
      this.customerId = this.authService.getCustomerId();
      const userRole = this.authService.getUserRole();
      if (this.machineId) {
        this.machineService.getMachineDetails(this.machineId, userRole).subscribe({
          next: data => {
            this.machine = data[0];
          },
          error: error => console.error('Error retrieving machine details:', error)
        });
        this.machineService.getMachineAssets(this.machineId).subscribe({
          next: data => {
            this.assets = data;
            this.assets.forEach(asset => {
              asset.assetPath = asset ? `${asset.assetPath}` : null;
            });
          },
          error: error => {
            console.error('Error retrieving assets:', error);
          }
        });
      }
    });
  }

  onDownloadClick(assetId: number) {
    this.machineService.getSignedUrl(assetId).subscribe({
      next: data => {
        const signedUrl = data.signedUrl;
        const link = document.createElement('a');
        link.href = signedUrl;
        link.target = '_blank';
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      error: error => {
        console.error('Error retrieving signed URL:', error);
      }
    });
  }

  trackById(index: number, asset: Asset): number {
    return asset.assetId;
  }
}

import { Component } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerDetail } from "../../types/customer.type";

@Component({
  selector: 'app-customers-new',
  templateUrl: './customers-new.component.html',
})
export class CustomersNewComponent {
  customer: CustomerDetail | undefined;
  customerId: string | null = "";

  constructor(private api: ApiService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.parent?.paramMap.subscribe(params => {
      this.customerId = params.get('id');
      this.customer = {
        "customer": "",
        "mate": "",
        "email": "",
        "city": "",
        "street": "",
        "state": "",
        "zip": "",
        "customerNumber": ""
      };
    });
  }

  onSubmit(formData: any) {
    this.api.post<CustomerDetail>(`portal/customer/Neu`, formData).subscribe({
      next: (response) => {
        this.toastr.success("Customer was successfully created");
        this.router.navigate([`/customers/detail/${response.customerId}`]);
      },
      error: (err) => {
        this.toastr.error("There was an error creating the customer");
        console.error(err);
      }
    });
  }

  cancel() {
    this.router.navigate(['/customers']);
  }

}

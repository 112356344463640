import { Component } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { AuthService } from "../../../../core/services/auth.service";
import { Title } from "@angular/platform-browser";
import { Asset } from "../../../customers/types/asset.type";
import { MachinesService } from "../../services/machines.service";
import { forkJoin, map, switchMap } from "rxjs";
import { PageTitleService } from "../../../../shared/services/page-title.service";

type Machine = {
  machineId: number,
  customerId: string,
  machineName: string,
  machineCity: string,
  machineTag?: string,
  image?: string | null;
}


@Component({
  selector: 'app-machines-overview',
  templateUrl: './machines-overview.component.html',
})
export class MachinesOverviewComponent {

  machines: Machine[] = [];
  filteredMachines: Machine[] = [];
  assets: Asset[] | undefined;
  currentFilter: any;

  constructor(private machineService: MachinesService, private api: ApiService, private authService: AuthService,  private pageTitleService: PageTitleService,) {
    this.pageTitleService.setTitle("Machines")
  }

  ngOnInit() {
    this.loadMachines();
  }

  onFilterTextBoxChanged(filter: string) {
    this.currentFilter = filter
  }

  private loadMachines() {
    const customerId: string = this.authService.getCustomerId();
    const userRole = this.authService.getUserRole();
    const baseUrl = userRole === 'Administrator' ? 'portal/machine' : `portal/machine/customer/${customerId}`;

    this.api.get<Machine[]>(baseUrl).pipe(
      switchMap(machines => {
        this.machines = machines;
        const assetRequests = machines.map(machine =>
          this.machineService.getMachineAssetsNumber(machine.machineId).pipe(
            map(assets => ({ machineId: machine.machineId, assets }))
          )
        );
        return forkJoin(assetRequests);
      }),
      map(assetResponses => {
        assetResponses.forEach(response => {
          const machineIndex = this.machines.findIndex(machine => machine.machineId === response.machineId);
          this.machines[machineIndex].image = response.assets.find(asset => asset.assetCategory === 'Image')?.assetPath ?? null;
        });
        return this.machines;
      })
    ).subscribe(
      {
        next: machines => {
          this.machines = machines;
          this.filteredMachines = userRole === 'Administrator' ? this.machines : this.machines.filter(machine => machine.customerId === customerId);
        },
        error: error => {
          console.error('Error retrieving machines or assets:', error)
        }
      });
  }
}

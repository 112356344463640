import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private titleSubject = new BehaviorSubject<string>('Default Title');

  public title$: Observable<string> = this.titleSubject.asObservable();

  constructor(private titleService: Title) {
  }

  public setTitle(newTitle: string): void {
    setTimeout(() => {
      this.titleSubject.next(newTitle);  // Aktualisieren des BehaviorSubject
      this.titleService.setTitle(newTitle);  // Aktualisieren des Titels im Browser
    }, 0);
  }
}

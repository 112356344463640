import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./core/guards/auth.guard";
import { RoleGuard } from "./core/guards/roles.guard";

const routes: Routes = [
  {
    path: '', redirectTo: 'machines', pathMatch: 'full', data: {
      breadcrumb: 'Home',
      canActivate: [AuthGuard],
    }
  },
  {
    path: 'customers',
    loadChildren: () => import('./features/customers/customers.module').then(m => m.CustomersModule),
    data: {breadcrumb: 'Customers'},
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'machines',
    loadChildren: () => import('./features/machines/machines.module').then(m => m.MachinesModule),
    data: {breadcrumb: 'Machines'},
    canActivate: [AuthGuard],
  },
  {
    path: 'contacts',
    loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule),
    data: {breadcrumb: 'Contact us'},
    canActivate: [AuthGuard],
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./features/forbidden/forbidden.module').then(m => m.ForbiddenModule),
    data: {breadcrumb: 'Forbidden'},
  },
  {
    path: 'dataprotection',
    loadChildren: () => import('./features/protection/protection.module').then(m => m.ProtectionModule),
    data: {breadcrumb: 'Data Protection'},
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiService } from "../../services/api.service";
import { jwtDecode } from "jwt-decode";
import { Router } from "@angular/router";

interface DecodedToken {
  role: string;
  adminExp?: number;
  userExp: number;
  customerId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userRole: string = "";
  customerId: string = "";
  private authUrl = 'auth';

  constructor(private api: ApiService, private router: Router) {
    this.loadUserDetails();
  }

  requestMagicLink(email: string): Observable<any> {
    return this.api.post(`${this.authUrl}/login`, {destination: email});
  }

  public getUserRole(): string {
    return this.userRole;
  }

  public getCustomerId(): string {
    return this.customerId;
  }

  hasAccess(customerId: string): Observable<boolean> {
    return of(this.getCustomerId() === customerId);
  }

  isLoggedIn(): boolean {
    const token = this.getAccessToken();
    return !!token && !this.isTokenExpired(token);
  }

  saveToken(token: string): void {
    localStorage.setItem('auth_token', token);
  }

  saveAccessToken(token: string): void {
    localStorage.setItem('auth_accesstoken', token);
  }

  getToken(): string | null {
    return localStorage.getItem('auth_token');
  }

  getAccessToken(): string | null {
    return localStorage.getItem('auth_accesstoken');
  }

  validateToken(): Observable<boolean> {
    const token = this.getAccessToken();
    if (!token || this.isTokenExpired(token)) {
      this.logout();
      return of(false);
    }

    return this.api.get('auth/login/validate').pipe(
      map((response: any) => true),
      catchError(error => {
        this.logout();
        return of(false);
      })
    );
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_accesstoken');
    this.router.navigate(['auth/login']).then(() => {
      window.location.reload();
    });
  }

  private loadUserDetails() {
    const token = localStorage.getItem('auth_accesstoken');
    if (token) {
      const decodedToken = jwtDecode<DecodedToken>(token);
      if (this.isTokenExpired(token)) {
        this.logout();
      } else {
        this.userRole = decodedToken.role;
        this.customerId = decodedToken.customerId;
      }
    }
  }

  private isTokenExpired(token: string): boolean {
    const decodedToken = jwtDecode<DecodedToken>(token);
    const expirationTime = decodedToken.role === 'Administrator' && decodedToken.adminExp
      ? decodedToken.adminExp * 1000
      : decodedToken.userExp * 1000;
    return Date.now() > expirationTime;
  }

}

import { NgModule } from '@angular/core';
import { MachinesComponent } from "./pages/machines/machines.component";
import { MachinesDetailComponent } from "./pages/machines-detail/machines-detail.component";
import { SharedModule } from "../../shared/shared.module";
import { MachinesRoutingModule } from "./machines-routing.module";
import { MachinesDownloadsComponent } from "./pages/machines-downloads/machines-downloads.component";
import { MachinesModelComponent } from "./pages/machines-model/machines-model.component";
import { MachinesOverviewComponent } from "./pages/machines-overview/machines-overview.component";


@NgModule({
  declarations: [
    MachinesComponent,
    MachinesDetailComponent,
    MachinesDownloadsComponent,
    MachinesModelComponent,
    MachinesOverviewComponent
  ],
  imports: [
    SharedModule,
    MachinesRoutingModule
  ]
})
export class MachinesModule {
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersComponent } from "./pages/customers/customers.component";
import { CustomersOverviewComponent } from "./pages/customers-overview/customers-overview.component";
import { CustomersDetailComponent } from "./pages/customers-detail/customers-detail.component";
import { CustomersAdminComponent } from "./pages/customers-admin/customers-admin.component";
import { CustomersUsersComponent } from "./pages/customers-users/customers-users.component";
import { CustomersMachinesComponent } from "./pages/customers-machines/customers-machines.component";
import { CustomersNewComponent } from "./pages/customers-new/customers-new.component";

const routes: Routes = [
  {
    path: '',
    component: CustomersComponent,
    data: {
      breadcrumb: 'Customer'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview'
      },
      {
        path: 'overview',
        component: CustomersOverviewComponent,
        data: {
          breadcrumb: 'Overview'
        }
      },
    ]
  },
  {
    path: "detail/new",
    component: CustomersNewComponent,
    data: {
      breadcrumb: 'New'
    },
  },
  {
    path: "detail/:id",
    component: CustomersDetailComponent,
    data: {
      breadcrumb: 'Detail'
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'admin'
      },
      {
        path: 'admin',
        component: CustomersAdminComponent,
        data: {
          breadcrumb: 'Admininistration'
        }
      },
      {
        path: 'users',
        component: CustomersUsersComponent,
        data: {
          breadcrumb: 'User'
        }
      },
      {
        path: 'machines',
        component: CustomersMachinesComponent,
        data: {
          breadcrumb: 'Machines'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule {
}

// auth.guard.ts

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, tap } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  canActivate: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean => {
    return this.authService.validateToken().pipe(
      tap(isValid => {
        if (!isValid) {
          this.router.navigate(['auth/login'], { queryParams: { redirectUrl: state.url }});
        }
      })
    );
  }

  constructor(private authService: AuthService, private router: Router) {
  }


}

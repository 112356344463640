import { Component } from '@angular/core';
import { AuthService } from "./core/services/auth.service";
import { LinkState, LinkStateService } from "./shared/services/link-state.service";
import { Observable } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  loggedIn: boolean = false;
  backLink$: Observable<LinkState>;
  protected readonly document = document;

  constructor(private authService: AuthService, private linkState: LinkStateService,) {
    this.backLink$ = this.linkState.backLink$;
  }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn();
  }

}

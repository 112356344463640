<div>
  <h1 class="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl">We’d love to hear from you</h1>
  <p class="mt-3 text-gray-500">Our friendly team is always here to chat.</p>
</div>

<div class="grid grid-cols-1 gap-12 mt-10 sm:grid-cols-2 xl:grid-cols-4">
  <ng-container *ngFor="let item of contact">
    <div (click)="selectContact(item)" [class]="getItemClass(item)"
         class="cursor-pointer p-4 rounded-lg bg-blue-50 hover:bg-blue-100 md:p-6 dark:bg-[#202836] dark:hover:bg-sky-950">
      <span [class]="getIconClass(item)" class="inline-flex p-3 text-blue-400 rounded-lg bg-blue-100/80 dark:bg-sky-950">
        <ng-icon [name]="item.icon" class="relative top-0 leading-normal text-blue-400 ni ni-tv-2 text-2xl"></ng-icon>
      </span>
      <h2 class="mt-4 text-base font-medium text-gray-800">{{ item.title }}</h2>
      <p class="mt-2 text-sm text-gray-500">{{ item.description }}</p>
      <p class="block mt-2 text-sm text-blue-400">{{ item.email }}</p>
    </div>
  </ng-container>
</div>


<div *ngIf="selectedEmail" class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
  <h2 class="mt-2 text-1xl font-semibold text-gray-800 md:text-2xl mb-2">{{ this.selectedTitle }} Support</h2>
  <form (ngSubmit)="sendEmail()" [formGroup]="kontaktForm" class="space-y-8" novalidate>
    <div>
      <label class="inputLabel" for="subject">Your Name</label>
      <input class="input" formControlName="name" id="name" placeholder="Max Mustermann" required type="text">
    </div>
    <div>
      <label class="inputLabel" for="email">Your email</label>
      <input class="input" formControlName="userEmail" id="email" placeholder="example@pcagmbh.de" required
             type="email">
    </div>
    <div>
      <label class="inputLabel" for="subject">Subject</label>
      <input class="input" formControlName="subject" id="subject" placeholder="Let us know how we can help you"
             required type="text">
    </div>
    <div class="sm:col-span-2">
      <label class="inputLabel" for="message">Your message</label>
      <textarea class="input" formControlName="message" id="message" placeholder="Leave a comment..."
                rows="6"></textarea>
    </div>
    <button class="submitButton" type="submit">Send message</button>
  </form>
</div>


import { NgModule } from '@angular/core';
import { SharedModule } from "../../shared/shared.module";
import { ForbiddenRoutingModule } from "./forbidden-routing.module";
import { ForbiddenComponent } from "./pages/forbitten/forbidden.component";
import { ForbiddenDetailComponent } from "./pages/forbitten-detail/forbidden-detail.component";
import { ForbiddenOverviewComponent } from "./pages/forbitten-overview/forbidden-overview.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import { PageTitleService } from "../../shared/services/page-title.service";


@NgModule({
  declarations: [
    ForbiddenComponent,
    ForbiddenDetailComponent,
    ForbiddenOverviewComponent
  ],
  imports: [
    SharedModule,
    ForbiddenRoutingModule,
    ReactiveFormsModule
  ]
})
export class ForbiddenModule {
  constructor(private pageTitleService: PageTitleService) {
    this.pageTitleService.setTitle("Forbidden")
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-forbidden-overview',
  templateUrl: './forbidden-overview.component.html',
  styleUrls: ['./forbidden-overview.component.css']
})
export class ForbiddenOverviewComponent {

}

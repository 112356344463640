<app-sidebar *ngIf="loggedIn" class="z-50 flex-shrink-0"/>

<main *ngIf="!loggedIn" class="relative h-screen transition-all duration-200 ease-in-out">
  <div class="w-screen h-screen">
    <router-outlet></router-outlet>
  </div>
</main>

<main *ngIf="loggedIn" class="relative overflow-auto pt-2 pr-2 flex flex-col flex-grow ">
  <div class="bg-white rounded-t-lg overflow-auto shadow-sm h-full dark:bg-zinc-800">
    <header class="pt-4 px-8 pb-6">
      <div *ngIf="(backLink$ | async)?.visible" class="block mb-2">
        <a [routerLink]="(backLink$ | async)?.link">{{ (backLink$ | async)?.text }}</a>
      </div>
      <h1 class="text-2xl">
        {{ document.title }}
      </h1>
    </header>
    <div class="px-8">
      <router-outlet></router-outlet>
    </div>

  </div>
</main>

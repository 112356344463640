import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = 'https://backend.docs.pcagmbh.de'; // URL Ihres NestJS-Backends oder. http://localhost:3000

  constructor(private http: HttpClient) {
  }

  // Allgemeine Methode für GET-Anfragen
  get<T>(path: string, p?: { headers: HttpHeaders }): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${path}`);
  }

  post<T>(path: string, body: any): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}/${path}`, body);
  }

  delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}/${path}`);
  }

  patch<T>(path: string, body: any): Observable<T> {
    return this.http.patch<T>(`${this.baseUrl}/${path}`, body);
  }

  // Weitere Methoden für POST, PUT, DELETE usw.
}

@if (customer) {
  <form class="p-6" #customerForm="ngForm">
    <div class="space-y-12">

      <div class="border-b border-gray-900/10 pb-12">

        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

          <div class="sm:col-span-3">
            <label for="customerNumber" class="inputLabel">Company Number</label>
            <div class="mt-2">
              <input [(ngModel)]="customer.customerNumber" type="text" name="customerNumber" id="customerNumber" autocomplete="off"
                     class="input">
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="customer" class="inputLabel">Company</label>
            <div class="mt-2">
              <input [(ngModel)]="customer.customer" type="text" name="customer" id="customer" autocomplete="off"
                     class="input">
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="mate" class="inputLabel">Contact person</label>
            <div class="mt-2">
              <input [(ngModel)]="customer.mate" type="text" name="mate" id="mate" autocomplete="off"
                     class="input">
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="email" class="inputLabel">E-mail</label>
            <div class="mt-2">
              <input [(ngModel)]="customer.email" id="email" name="email" type="email" autocomplete="email"
                     class="input">
            </div>
          </div>

          <div class="col-span-full">
            <label for="street" class="inputLabel">Street</label>
            <div class="mt-2">
              <input [(ngModel)]="customer.street" type="text" name="street" id="street" autocomplete="off"
                     class="input">
            </div>
          </div>

          <div class="sm:col-span-2 sm:col-start-1">
            <label for="city" class="inputLabel">City</label>
            <div class="mt-2">
              <input [(ngModel)]="customer.city" type="text" name="city" id="city" autocomplete="off"
                     class="input">
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="state" class="inputLabel">State</label>
            <div class="mt-2">
              <input [(ngModel)]="customer.state" type="text" name="state" id="state" autocomplete="off"
                     class="input">
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="zip" class="inputLabel">ZIP</label>
            <div class="mt-2">
              <input [(ngModel)]="customer.zip" type="text" name="zip" id="zip" autocomplete="off"
                     class="input">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="button" (click)="cancel()"
              class="deleteButton">Cancel
      </button>
      <button type="submit" (click)="onSubmit(customerForm.value)"
              class="saveButton">Add
      </button>
    </div>
  </form>
}

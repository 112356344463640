@if (navigation.length > 0) {
  <ul class="flex flex-wrap py-3 ">
    @for (item of navigation; track item) {
      <li>
        <a [routerLink]="item.route" class="block pt-3.5 px-3 pb-2 text-sm border-b-2 "
           routerLinkActive="border-blue-400 text-blue-400 font-bold dark:!text-blue-400">
          {{ item.title }}
        </a>
      </li>
    }
  </ul>
}

<form>
  <div
    class="flex flex-wrap relative items-stretch w-full transition-all rounded-lg ease ">
            <span
              class="text-lg ease leading-5.6 absolute z-50 -ml-px flex items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
              <span class="material-symbols-outlined">
              search
              </span>
            </span>
    <input (input)="filter()"
           [(ngModel)]="searchString"
           [ngModelOptions]="{standalone: true}"
           class="pl-9 text-sm focus:shadow-primary-outline ease w-1/100 leading-5.6 relative  block min-w-0 flex-auto rounded-lg border border-solid border-gray-300 dark:bg-slate-850 bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
           placeholder="Search"
           type="text">
  </div>
</form>

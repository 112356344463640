<a (click)="addUser()" class="absolute cursor-pointer right-14 bottom-6 flex px-8 py-2 font-bold bg-blue-50 hover:bg-blue-100 text-green-600 rounded-lg text-sm dark:bg-zinc-700 dark:hover:bg-zinc-900"
   type="button">
  <span class="text-4xl material-symbols-outlined">add</span>
</a>

<app-search-bar (searchValue)="onFilterTextBoxChanged($event)" class="block mb-3"></app-search-bar>

<table class="items-center w-full mb-0 align-top border-collapse text-slate-500 table-fixed">
  <caption class="hidden">Customer Users</caption>
  <thead>
  <tr>
    <th
      class="tableH">
      First name
    </th>
    <th
      class="tableH">
      Surname
    </th>
    <th
      class="tableH">
      E-mail
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users | filter: currentFilter; let i = index"  [ngClass]="{
        'bg-gray-50': i % 2 === 0,
        'bg-white': i % 2 !== 0,
        'dark:bg-gray-800': i % 2 === 0,
        'dark:bg-gray-700': i % 2 !== 0
     }"
      class="cursor-pointer border-b last:border-b-0 hover:bg-gray-200 dark:hover:bg-gray-900">
    <td class="tableD">
      <input *ngIf="user.editable" [(ngModel)]="user.name" class="input"
             placeholder="Name"
             type="text"/>
      <span *ngIf="!user.editable" class="px-2 py-1">{{ user.name }}</span>
    </td>
    <td class="tableD">
      <input *ngIf="user.editable" [(ngModel)]="user.surname" class="input"
             placeholder="Nachname"
             type="text"/>
      <span *ngIf="!user.editable" class="px-2 py-1">{{ user.surname }}</span>
    </td>
    <td class="tableD">
      <input *ngIf="user.editable" [(ngModel)]="user.email" class="input"
             placeholder="E-mail"
             type="text"/>
      <span *ngIf="!user.editable" class="px-2 py-1">{{ user.email }}</span>
    </td>
    <td class="pr-6 text-end align-middle bg-transparent whitespace-nowrap shadow-transparent border-t-solid border-t">
      <span (click)="editUser(user)" *ngIf="!user.editable"
            class="inline-flex p-1 text-blue-500 rounded mr-2 bg-blue-50 hover:bg-blue-100 dark:bg-zinc-700 dark:hover:bg-zinc-900">
        <ng-icon class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl" name="matEditOutline"/>
      </span>
      <span (click)="saveUser(user)" *ngIf="user.editable"
            class="inline-flex p-1 text-blue-500 rounded mr-2 bg-blue-50 hover:bg-blue-100 dark:bg-zinc-700 dark:hover:bg-zinc-900">
        <ng-icon class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl" name="matSaveAltOutline"/>
      </span>
      <span (click)="deleteUser(user)" class="inline-flex p-1 text-blue-500 rounded bg-blue-50 hover:bg-blue-100 dark:bg-zinc-700 dark:hover:bg-zinc-900">
          <ng-icon class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl" name="matDeleteOutline"/>
      </span>
    </td>
  </tr>
  </tbody>
</table>

import { Injectable } from '@angular/core';
import { Asset } from "../../customers/types/asset.type";
import { Observable } from "rxjs";
import { MachineDetail } from "../types/machineDetail.type";
import { ApiService } from "../../../services/api.service";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";


@Injectable({providedIn: 'root'})
export class MachinesService {

  constructor(private api: ApiService, private http: HttpClient, private toastr: ToastrService) {
  }


  getMachineDetails(machineId: string, userRole: string): Observable<MachineDetail[]> {
    const baseUrl = userRole === 'Administrator' ? `portal/machine/${machineId}` : `portal/machine/machines/${machineId}`;
    return this.api.get<MachineDetail[]>(baseUrl);
  }

  getMachineAssets(machineId: string): Observable<Asset[]> {
    return this.api.get<Asset[]>(`portal/asset/${machineId}`);
  }
  getMachineAssetsNumber(machineId: number): Observable<Asset[]> {
    return this.api.get<Asset[]>(`portal/asset/${machineId}`);
  }

  getSignedUrl(assetId: number): Observable<{ signedUrl: string }> {
    return this.api.get<{ signedUrl: string }>(`portal/asset/signed-url/${assetId}`);
  }

}

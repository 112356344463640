import { Component } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import { FormControl, FormGroup } from "@angular/forms";
import { PageTitleService } from "../../../../shared/services/page-title.service";

interface Contact {
  title: string;
  icon: string;
  description: string;
  email: string;
}

@Component({
  selector: 'app-contact-overview',
  templateUrl: './contact-overview.component.html',
})
export class ContactOverviewComponent {
  selectedEmail: string | null = null;
  selectedTitle: string | null = null;

  kontaktForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    subject: new FormControl(''),
    message: new FormControl(''),
    userEmail: new FormControl(''),
  });
  contact: Contact[] = [
    {
      title: 'Documentation',
      icon: 'matFolderOutline',
      description: 'Access detailed guides and manuals for all your products.',
      email: 'doku@pcagmbh.de'
    },
    {
      title: 'Service',
      icon: 'matAssignmentIndOutline',
      description: 'For maintenance and repair services, our expert team is ready to assist.',
      email: 'service@pcagmbh.de'
    },
    {
      title: 'Sales',
      icon: 'matShoppingCartOutline',
      description: 'Explore our range of our solutions with our knowledgeable sales team.',
      email: 'vertrieb@pcagmbh.de'
    },
    {
      title: 'Technical',
      icon: 'matCodeOutline',
      description: 'Receive immediate assistance for technical issues from our dedicated support staff.',
      email: 'edv@pcagmbh.de'
    }
  ]

  constructor(private api: ApiService, private toastr: ToastrService, private pageTitleService: PageTitleService) {
    this.pageTitleService.setTitle("Contact")
  }

  selectContact(contact: Contact) {
    this.selectedEmail = contact.email;
    this.selectedTitle = contact.title;
    this.kontaktForm.patchValue({email: this.selectedEmail});
  }


  sendEmail() {
    const formData = this.kontaktForm.value;
    this.api.post(`portal/contact/sendEmail`, formData).subscribe({
      next: () => {
        this.toastr.success("Email sent successfully");
        this.kontaktForm.reset();
      },
      error: () => this.toastr.error("Error sending form")
    });
  }

  getItemClass(item: Contact): string {
    return this.selectedTitle === item.title ? '!bg-blue-100 dark:!bg-sky-950' : '';
  }

  getIconClass(item: Contact): string {
    return this.selectedTitle === item.title ? '!bg-blue-200 dark:!bg-sky-950' : '';
  }


}

<table class="basis-1/2 items-center w-full mb-0 align-top border-collapse text-slate-500">
  <caption class="hidden">File Download</caption>
  <thead>
  <tr>
    <th
      class="tableH">
      File name
    </th>
    <th
      class="tableH">
      Type
    </th>
    <th
      class="tableH">
      Size
    </th>
  </tr>
  </thead>
  <tbody>
    @for (link of this.assets; track link) {
      @if (link.assetCategory === 'Downloadlink') {
        <tr class="hover:bg-gray-50 cursor-pointer border-b last:border-b-0 dark:hover:bg-zinc-700">
          <td
            class="tableD text-ellipsis overflow-hidden max-w-[250px]">
            <span class="px-2 py-1">
              {{ link.assetOriginalName }}
            </span>
          </td>
          <td
            class="tableD whitespace-nowrap shadow-transparent">
            <span class="px-2 py-1">
              {{ link.assetType }}
            </span>
          </td>
          <td
            class="tableD whitespace-nowrap shadow-transparent">
                <span class="px-2 py-1">
                  <ng-container *ngIf="link.assetSize < 1024 * 1024; else megabytes">
                    {{ link.assetSize / 1024 | number:'1.0-2' }} KB
                  </ng-container>
                  <ng-template #megabytes>
                    {{ link.assetSize / (1024 * 1024) | number:'1.0-2' }} MB
                  </ng-template>
                </span>

          </td>
          <td
            class="tableD whitespace-nowrap shadow-transparent border-t-solid border-t">
            <a (click)="onDownloadClick(link.assetId)" class="inline-flex p-1 text-blue-500 rounded bg-blue-50 hover:bg-blue-100 dark:bg-zinc-700 dark:hover:bg-zinc-900" href="javascript:void(0)" rel="noopener">
              <ng-icon class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl" name='matSaveAltOutline'></ng-icon>
            </a>
          </td>
        </tr>
      }
    }
  </tbody>
</table>


<div [ngClass]="{'w-16': !expanded, 'w-56': expanded}"
     class="bg-transparent relative flex flex-col justify-between h-full">
  <div class="main ">

    <!-- Header Section -->
    <div class="p-4">
      <div class="flex items-center gap-1.5 h-9 min-w-8 group">
        <span [ngClass]="{'group-hover:opacity-0': !expanded}"
              class="bg-white size-8 text-center flex justify-center items-center rounded-md shadow-sm">
          <img alt="Logo" class="p-2 object-contain h-full w-full" src="/assets/images/logo.svg">
        </span>
        @if (expanded) {
          <div class="max-w-28 overflow-hidden text-ellipsis whitespace-nowrap">
            PCA Roboter- und Verpackungstechnik GmbH
          </div>
        }
        <button (click)="expanded = !expanded"
                [ngClass]="{'opacity-0 group-hover:opacity-100': !expanded, 'opacity-85':expanded}"
                class="absolute rounded-lg right-3.5 h-9 flex items-center hover:bg-neutral-600/10">
          <img alt="" class="p-2" height="32" ngSrc="/assets/icons/sidebar.svg" width="32">
        </button>
      </div>
    </div>

    <ul class="flex items-start flex-col my-4">
      @for (item of nav; track item) {
        <li appDropdown class="px-2.5 mb-1 w-full relative" routerLinkActive="active">
          <a [routerLink]="item.route"
             [ngClass]="{'min-w-52': expanded, 'w-full': !expanded}"
             (click)="expanded = true"
             class="dropdown-toggle rounded-lg flex-1 flex items-center h-9 w-full px-3 mb-1 hover:bg-neutral-600/10 overflow-hidden">
            <div class="size-5 text-xl leading-5 material-symbols-outlined pointer-events-none">
              {{ item.icon }}
            </div>
            @if (expanded) {
              <p class="text-sm whitespace-nowrap text-ellipsis max-w-36 ml-2 pointer-events-none">{{ item.title }}</p>
            }
            @if (item.subitems) {
              @if (expanded) {
                <div class="absolute right-5 size-5 text-xl leading-5 material-symbols-outlined pointer-events-none ">
                  expand_more
                </div>
              }
            }
          </a>
          @if (item.subitems && expanded) {
            <ul class="dropdown flex flex-col h-0 overflow-hidden"
                [ngClass]="{'absolute rounded-sm left-full top-0 bg-white ms-2 min-w-32 shadow-lg': !expanded, 'ps-6': expanded}">
              @for (subitem of item.subitems; track subitem) {
                <li class="mb-1">
                  <a [routerLink]="subitem.route"
                     routerLinkActive="active"
                     class="rounded-md text-sm flex flex-1 items-center whitespace-nowrap text-ellipsis h-6 px-1.5 ml-2 hover:bg-neutral-600/10">
                    {{ subitem.title }}
                  </a>
                </li>
              }
            </ul>
          }
        </li>
      }

      <li class="flex justify-center absolute bottom-24 px-2.5 mb-1 w-full">
        <div class="relative flex items-center w-24 h-10 border-2 rounded-3xl">
          <div [ngClass]="{'translate-x-full': isDarkMode(), 'translate-x-0': !isDarkMode()}"
               class="absolute w-1/2 h-full bg-blue-500 rounded-2xl transition-transform duration-500 ease-in-out"></div>
          <button
            (click)="setLightMode()"
            class="relative z-10 w-1/2 text-center material-symbols-outlined transform transition duration-500 ease-in-out text-black dark:text-white">
            <span>light_mode</span>
          </button>
          <button
            (click)="setDarkMode()"
            class="relative z-10 w-1/2 text-center material-symbols-outlined transform transition duration-500 ease-in-out text-black dark:text-white">
            <span>dark_mode</span>
          </button>
        </div>
      </li>



      <li (click)="logout()" appDropdown class="flex justify-center cursor-pointer absolute bottom-10 px-2.5 mb-1 w-full"
          routerLinkActive="active">
        <a
          (click)="expanded = true"
          [ngClass]="{'min-w-52': expanded, 'w-full': !expanded}"
          class="dropdown-toggle rounded-lg flex-1 flex items-center h-9 w-full px-3 mb-1 hover:bg-neutral-600/10 overflow-hidden">
          <div class="size-5 text-xl leading-5 material-symbols-outlined pointer-events-none">
            <ng-icon class="text-red-500" name="matLogoutOutline"/>
          </div>
          @if (expanded) {
            <p class="text-sm whitespace-nowrap text-ellipsis max-w-36 ml-2 pointer-events-none mr-1">Sign out</p>
            <app-logoutTimer></app-logoutTimer>
          }
        </a>

      </li>

      @if (expanded) {
        <li class="cursor-pointer absolute bottom-2 px-2.5 mb-1 w-full"
            routerLinkActive="active">
          <div class="text-sm  flex justify-around">
              <a href="https://pcagmbh.de/impressum">imprint</a>
              <a href="/dataprotection">data protection</a>
          </div>
        </li>
      }

    </ul>
  </div>
</div>

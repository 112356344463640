import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDropdown]',
  standalone: true
})
export class DropdownDirective {

  isOpen = false;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('document:click', ['$event']) toggleOpen(event: Event) {
    const targetElement = event.target as HTMLElement;
    const insideClick = this.elRef.nativeElement.contains(targetElement);
    const isDropdownToggle = targetElement.classList.contains('dropdown-toggle');

    if (insideClick && isDropdownToggle) {
      const dropdownElement = this.elRef.nativeElement;
      const isOpen = dropdownElement.classList.contains('open');
      if (isOpen) {
        this.renderer.removeClass(dropdownElement, 'open');
      } else {
        this.renderer.addClass(dropdownElement, 'open');
      }
    }
  }
}

<div class="flex justify-end mb-4">
  <span (click)="toggleViewMode()"
        class="inline-flex cursor-pointer p-1 text-blue-500 rounded mr-2 bg-blue-50 hover:bg-blue-100 dark:bg-zinc-700 dark:hover:bg-zinc-900">
    <!-- Dynamisches Icon je nach Ansicht -->
    <ng-icon *ngIf="viewMode === 'grid'" class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl" name="matListOutline"></ng-icon>
    <ng-icon *ngIf="viewMode === 'list'" class="relative top-0 leading-normal text-blue-500 ni ni-tv-2 text-2xl" name="matGridOnOutline"></ng-icon>
  </span>
</div>

<div *ngIf="groupedMachines | keyvalue">
  <app-search-bar (searchValue)="onFilterTextBoxChanged($event)" class="block mb-3"></app-search-bar>
  <div *ngFor="let group of groupedMachines | keyvalue">
    <!-- Anstelle der customerId kannst du den Customer-Namen anzeigen, falls vorhanden -->
    <h2 *ngIf="userRole === 'Administrator'" class="text-xl font-bold mb-4">{{ group.key }}</h2>

    <!-- Grid-Ansicht -->
    <div *ngIf="viewMode === 'grid'" class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-10">
      <div *ngFor="let machine of group.value | filter: currentFilter" [routerLink]="['/machines/detail/', machine.machineId]" class="cursor-pointer">
        <div class="bg-white border border-gray-200 rounded-lg shadow dark:bg-zinc-800">
          <img *ngIf="machine.image" class="rounded-t-lg !w-full h-[250px] object-cover" [src]="machine.image" alt="{{ machine.machineName }}"/>
          <div class="p-5">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">{{ machine.machineName }}</h5>
            <p class="mb-3 font-normal text-gray-700">{{ machine.machineCity }}</p>
            <p class="font-normal text-gray-700">{{ machine.machineTag }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Listenansicht -->
    <div *ngIf="viewMode === 'list'" class="w-full overflow-x-auto">
      <table class="items-center w-full mb-10 align-top border-collapse text-slate-500 table-fixed">
        <caption class="hidden">Machines List</caption>
        <thead>
        <tr>
          <th class="tableH">Machine Name</th>
          <th class="tableH">City</th>
          <th class="tableH">Tag</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let machine of group.value | filter: currentFilter; let i = index"
            [ngClass]="{
            'bg-gray-50': i % 2 === 0,
            'bg-white': i % 2 !== 0,
            'dark:bg-gray-800': i % 2 === 0,
            'dark:bg-gray-700': i % 2 !== 0
          }" [routerLink]="['/machines/detail/', machine.machineId]"
            class="cursor-pointer border-b last:border-b-0 hover:bg-gray-200 dark:hover:bg-gray-900">
          <td class="tableD">
            <span class="px-2 py-1">{{ machine.machineName }}</span>
          </td>
          <td class="tableD">
            <span class="px-2 py-1">{{ machine.machineCity }}</span>
          </td>
          <td class="tableD">
            <span class="px-2 py-1">{{ machine.machineTag || 'N/A' }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #waitForResult>
  <div class='w-full min-h-16 mt-12'>
    <div class='h-1.5 w-full bg-blue-100 overflow-hidden'>
      <div class='animate-progress w-full h-full bg-blue-500 origin-left-right'></div>
    </div>
  </div>
</ng-template>

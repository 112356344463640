import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginCallbackComponent } from "./callback/login-callback.component";
import { LoginRegisterComponent } from "./register/login-register.component";

@NgModule({
  declarations: [LoginComponent, LoginCallbackComponent, LoginRegisterComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class AuthModule {
}

import { NgModule } from '@angular/core';
import { SharedModule } from "../../shared/shared.module";
import { ContactRoutingModule } from "./contact-routing.module";
import { ContactComponent } from "./pages/contact/contact.component";
import { ContactDetailComponent } from "./pages/contact-detail/contact-detail.component";
import { ContactOverviewComponent } from "./pages/contact-overview/contact-overview.component";
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
  declarations: [
    ContactComponent,
    ContactDetailComponent,
    ContactOverviewComponent
  ],
  imports: [
    SharedModule,
    ContactRoutingModule,
    ReactiveFormsModule
  ]
})
export class ContactModule {
}

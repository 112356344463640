import {Component, HostListener} from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CustomersMachinesService } from "../../services/customers-machines.service";
import { Machine } from "../../types/machine.type";
import { Asset } from "../../types/asset.type";
import { HttpEvent, HttpEventType } from "@angular/common/http";

@Component({
  selector: 'app-customers-machines',
  templateUrl: './customers-machines.component.html',
})

export class CustomersMachinesComponent {
  machine: Machine | undefined;
  machines: Machine[] | undefined;
  assets: Asset[] | undefined;
  selectedMachine: Machine | undefined;
  currentFilter: string = "";
  customerId: number | null = null;

  openModal = false;
  isAddDownloadlink = false;
  assetImage = false;
  isAddImage = false;
  isAddModel = false;
  assetModel = false;
  assetsToDelete: number[] = [];
  selectedFiles: { [key: string]: File | File[] } = {};
  isLoading = true;
  uploadProgress: number = 0;
  remainingTime: string = "";

  private uploadStartTime: number = 0;
  private lastUploadedBytes: number = 0;
  private lastTimeStamp: number = 0;

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private customersMachinesService: CustomersMachinesService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.route.parent?.paramMap.subscribe(params => {
      const id = params.get('id');
      this.customerId = id ? +id : null;
      if (this.customerId) {
        this.customersMachinesService.getMachines(this.customerId);
        this.customersMachinesService.machines$.subscribe(machines => {
          this.machines = machines;
        });
      }
    });
  }

  editMachine(machine: any) {
    this.selectedMachine = { ...machine };

    const machineId = this.selectedMachine?.machineId;

    if (machineId) {
      this.customersMachinesService.getAssets(machineId).subscribe({
        next: data => {
          this.assets = data;

          // Kategorien verarbeiten
          this.assets.forEach(asset => {
            if (asset.assetCategory === 'Image') {
              this.assetImage = true;
            }
            if (asset.assetCategory === 'Model') {
              this.assetModel = true;
            }
          });
        },
        error: error => {
          this.toastr.error('Error retrieving assets:', error);
        }
      });
    }

    this.openModal = true;
  }

  onFileSelected(event: any, type: string) {
    if (type === 'downloadlinks') {
      this.selectedFiles[type] = Array.from(event.target.files);
    } else {
      this.selectedFiles[type] = event.target.files[0];
    }
  }

  markAssetForDeletion(assetId: number) {
    const asset = this.assets?.find(a => a.assetId === assetId);
    if (asset) {
      asset.markedForDeletion = true;
      this.assetsToDelete.push(assetId);
    }
  }

  cancelDeletions() {
    this.assets?.forEach(asset => {
      asset.markedForDeletion = false;
    });
    this.assetsToDelete = [];
    this.openModal = false;
  }

  addMachine() {
    this.openModal = true;

    const newMachine: Machine = {
      customerId: this.customerId ?? 0,
      machineId: this.generateRandomId(),
      machineName: '',
      machineCity: '',
      machineTag: '',
      editable: true,
    };
    this.selectedMachine = newMachine;

    if (Array.isArray(this.machines)) {
      this.machines.push(newMachine);
    } else {
      this.machines = [newMachine];
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.openModal && event.key === 'Enter') {
      this.saveMachine(this.selectedMachine);
    }
  }

  async saveMachine(machine: any) {
    // Logge den Beginn der Speicherung der Maschine
    console.log('Start saving machine:', machine);

    // Falls keine Maschine ausgewählt wurde
    if (!machine) {
      this.toastr.error('No machine data selected!');
      console.error('Error: No machine data selected!');
      return;
    }

    // Zuerst die Maschinendaten verarbeiten
    if (this.customerId) {
      console.log('Saving machine data...');
      await this.customersMachinesService.saveMachine(this.customerId, machine).toPromise();
      console.log('Machine data saved successfully.');
    }

    // Dateien für den Upload vorbereiten
    console.log('Preparing files for upload...');

    if (this.selectedFiles['image'] && this.selectedFiles['image'] instanceof File) {
      console.log('Uploading image...');
      await this.uploadFileToS3(this.selectedFiles['image'], 'image', machine.machineId);
      console.log('Image uploaded successfully.');
    }

// Beispiel: Modell hochladen
    if (this.selectedFiles['model'] && this.selectedFiles['model'] instanceof File) {
      console.log('Uploading model...');
      await this.uploadFileToS3(this.selectedFiles['model'], 'model', machine.machineId);
      console.log('Model uploaded successfully.');
    }

// Beispiel: Downloadlinks hochladen
    if (this.selectedFiles['downloadlinks'] && Array.isArray(this.selectedFiles['downloadlinks'])) {
      for (let i = 0; i < this.selectedFiles['downloadlinks'].length; i++) {
        const file = this.selectedFiles['downloadlinks'][i];
        if (file instanceof File) {
          console.log(`Uploading downloadlink part ${i + 1}...`);
          await this.uploadFileToS3(file, `downloadlinks[${i}]`, machine.machineId);
          console.log(`Downloadlink part ${i + 1} uploaded successfully.`);
        }
      }
    }

    this.assetsToDelete.forEach(assetId => {
      this.deleteAsset(assetId);
    });

    this.toastr.success('Machine and files successfully saved.');
    console.log('All files uploaded and machine saved successfully.');
    window.location.reload();
  }

// Datei in den S3-Bucket hochladen
  async uploadFileToS3(file: File, fileType: string, machineId: number) {
    console.log(`Starting upload for ${fileType}:`, file.name);

    // Hole signierte URLs und die Upload-ID
    const response = await this.customersMachinesService.getSignedUploadUrl(file, machineId).toPromise();
    const { signedUrls, uploadId, key } = response;

    console.log("Frontend key:", key);
    const chunkSize = 5 * 1024 * 1024; // 5 MB

    const chunks: Blob[] = [];
    let uploadedBytes = 0;
    const totalBytes = file.size;


    // Datei in Chunks aufteilen
    for (let start = 0; start < file.size; start += chunkSize) {
      const chunk = file.slice(start, start + chunkSize);
      chunks.push(chunk);
    }

    console.log(`Number of file chunks: ${chunks.length}`);
    console.log(`Number of signed URLs received: ${signedUrls.length}`);

    // Array zum Speichern von Upload-Promises und ETags
    const uploadPromises: Promise<any>[] = [];
    const etags: { PartNumber: number; ETag: string }[] = [];

    // Hochladen jedes Chunks
    for (let i = 0; i < chunks.length; i++) {
      const signedUrl = signedUrls[i].signedUrl;
      const partNumber = signedUrls[i].partNumber;

      // Lade jedes Teil hoch und speichere das ETag
      const uploadPromise = this.uploadChunk(signedUrl, chunks[i], partNumber)
        .then((result) => {
          if (result) {
            etags.push(result); // Füge nur erfolgreiche Uploads hinzu
            uploadedBytes += chunks[i].size;
            this.uploadProgress = Math.round((uploadedBytes / totalBytes) * 100);
          }
        })
        .catch((error) => {
          console.error(`Error uploading chunk ${partNumber}:`, error);
        });
      uploadPromises.push(uploadPromise);
    }

    // Warte, bis alle Teile hochgeladen sind
    await Promise.all(uploadPromises);

    // Sortiere die etags nach PartNumber (wichtig für S3)
    etags.sort((a, b) => a.PartNumber - b.PartNumber);

    console.log('ETags collected:', etags);

    // Abschluss des Uploads im Backend
    try {
      await this.customersMachinesService.completeMultipartUpload(uploadId, key, etags).toPromise();
      console.log('All chunks uploaded and file merged successfully');
      this.toastr.success('File uploaded successfully');
      this.uploadProgress = 100;
    } catch (error) {
      console.error('Error completing multipart upload:', error);
      this.toastr.error('Error completing file upload');
    }

    this.assetsToDelete = [];
  }

// Hilfsmethode zum Hochladen eines Chunks
  async uploadChunk(signedUrl: string, chunk: Blob, partNumber: number, retries: number = 3): Promise<{ PartNumber: number; ETag: string } | null> {
    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: chunk,
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to upload chunk ${partNumber}. HTTP status: ${response.status}`);
      }

      const eTag = response.headers.get('ETag');
      if (!eTag) {
        throw new Error(`Missing ETag for chunk ${partNumber}`);
      }

      console.log(`Chunk ${partNumber} uploaded successfully`);
      return { PartNumber: partNumber, ETag: eTag };
    } catch (error) {
      if (retries > 0) {
        console.warn(`Error uploading chunk ${partNumber}, retrying... (${retries} retries left)`);
        return this.uploadChunk(signedUrl, chunk, partNumber, retries - 1); // Retry logic
      } else {
        console.error(`Failed to upload chunk ${partNumber} after multiple attempts`, error);
        return null; // Return null if it fails after all retries
      }
    }
  }

  deleteMachine(machine: any) {
    this.customersMachinesService.deleteMachine(machine.machineId, machine.machineName).subscribe();
    this.customersMachinesService.deleteAsset(machine.machineId, machine.machineName).subscribe();
  }

  deleteAsset(assetId: number) {
    this.customersMachinesService.deleteAssetSingle(assetId).subscribe();
  }

  generateRandomId = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const num = array[0] % 900000; // Reduzieren auf den Bereich 0-899999
    return num + 100000;
  };

  onFilterTextBoxChanged(filterValue: string) {
    this.currentFilter = filterValue;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgIconsModule } from "@ng-icons/core";
import {
  matArchiveOutline,
  matAssignmentIndOutline,
  matChatOutline,
  matChevronRightOutline,
  matCloseOutline,
  matCodeOutline,
  matDatasetOutline,
  matDeleteOutline,
  matEditOutline,
  matFolderOutline,
  matKeyboardArrowDownOutline,
  matLogoutOutline,
  matSaveAltOutline,
  matSearchOutline,
  matShoppingCartOutline,
  matSimCardDownloadOutline,
  matListOutline,
  matGridOnOutline
} from "@ng-icons/material-icons/outline";
import { FilterPipe } from "./Pipes/filter.pipe";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { TabNavComponent } from "./components/tab-nav/tab-nav.component";


@NgModule({
  declarations: [FilterPipe, SearchBarComponent, TabNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgIconsModule.withIcons({
      matChevronRightOutline,
      matCloseOutline,
      matDatasetOutline,
      matSearchOutline,
      matAssignmentIndOutline,
      matArchiveOutline,
      matChatOutline,
      matCodeOutline,
      matShoppingCartOutline,
      matFolderOutline,
      matEditOutline,
      matSaveAltOutline,
      matKeyboardArrowDownOutline,
      matSimCardDownloadOutline,
      matDeleteOutline,
      matLogoutOutline,
      matListOutline,
      matGridOnOutline
    }),

  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgIconsModule,
    FilterPipe,
    SearchBarComponent,
    TabNavComponent
  ]
})
export class SharedModule {
}

<a class="cursor-pointer absolute right-14 bottom-6 flex px-8 py-2 font-bold bg-blue-50 hover:bg-blue-100 text-green-600 rounded-lg text-sm dark:bg-zinc-700 dark:hover:bg-zinc-900" routerLink="../../customers/detail/new"
   type="button">
  <span class="text-4xl material-symbols-outlined">add</span>
</a>

<app-search-bar (searchValue)="onFilterTextBoxChanged($event)" class="block mb-3"></app-search-bar>

<table class="items-center w-full mb-0 align-top border-collapse text-slate-500">
  <caption class="hidden">Customer Overview</caption>
  <thead>
  <tr>
    <th
      class="px-6 py-3 font-bold text-left uppercase text-slate-400 opacity-70 whitespace-nowrap tracking-normal text-xs border-b-solid border-b border-collapse ">
      Customer ID
    </th>
    <th
      class="px-6 py-3 font-bold text-left uppercase text-slate-400 opacity-70 whitespace-nowrap tracking-normal text-xs border-b-solid border-b border-collapse ">
      Customer
    </th>
    <th
      class="px-6 py-3 font-bold text-left uppercase text-slate-400 opacity-70 whitespace-nowrap tracking-normal text-xs border-b-solid border-b border-collapse ">
      Contact person
    </th>
    <th
      class="px-6 py-3 font-bold text-left uppercase text-slate-400 opacity-70 whitespace-nowrap tracking-normal text-xs border-b-solid border-b border-collapse ">
      E-mail
    </th>
    <th
      class="px-6 py-3 font-bold text-left uppercase text-slate-400 opacity-70 whitespace-nowrap tracking-normal text-xs border-b-solid border-b border-collapse ">
      Place
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let customer of customers | filter: currentFilter; let i = index"
      [routerLink]="['/customers/detail/', customer.customerId]" [ngClass]="{
        'bg-gray-50': i % 2 === 0,
        'bg-white': i % 2 !== 0,
        'dark:bg-gray-800': i % 2 === 0,
        'dark:bg-gray-700': i % 2 !== 0
     }"
      class="hover:bg-gray-50 cursor-pointer border-b last:border-b-0hover:bg-gray-200 dark:hover:bg-gray-900">
    <td
      class="tableD">
          <span class="px-2 py-1">
            {{ customer.customerNumber }}
          </span>
    </td>
    <td
      class="tableD">
          <span class="px-2 py-1">
            {{ customer.customer }}
          </span>
    </td>
    <td
      class="tableD">
          <span class="px-2 py-1">
            {{ customer.mate }}
          </span>
    </td>
    <td
      class="tableD">
          <span class="px-2 py-1">
            {{ customer.email }}
          </span>
    </td>
    <td
      class="tableD">
          <span class="px-2 py-1">
            {{ customer.city }}
          </span>
    </td>
  </tr>
  </tbody>
</table>


import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { FormControl, FormGroup } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { PageTitleService } from "../../shared/services/page-title.service";

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
})
export class LoginRegisterComponent {
  registerForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    company: new FormControl(''),
  });

  constructor(private api: ApiService, private toastr: ToastrService,private pageTitleService: PageTitleService, private router: Router) {
    this.pageTitleService.setTitle("Register")
  }


  sendEmail() {
    const formData = this.registerForm.value;
    this.api.post(`portal/register/sendRegisterEmail`, formData).subscribe({
      next: () => {
        this.toastr.success("Email sent successfully");
        this.registerForm.reset();
        this.router.navigate(['auth/login']);
      },
      error: () => this.toastr.error("Error sending form")
    });
  }


}

import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkmodeService {
  private themeKey = 'theme';
  darkMode = signal<boolean>(this.getInitialTheme());

  constructor() {
    window.addEventListener('storage', this.syncThemeWithLocalStorage.bind(this));
  }

  private getInitialTheme(): boolean {
    const savedTheme = localStorage.getItem(this.themeKey);
    return savedTheme ? savedTheme === 'dark' : false;
  }

  setDarkMode(isDarkMode: boolean): void {
    this.darkMode.set(isDarkMode);
    localStorage.setItem(this.themeKey, isDarkMode ? 'dark' : 'light');
  }

  private syncThemeWithLocalStorage(event: StorageEvent): void {
    if (event.key === this.themeKey) {
      const newTheme = event.newValue === 'dark';
      this.darkMode.set(newTheme);
    }
  }
}

import { NgModule } from '@angular/core';
import { CustomersComponent } from "./pages/customers/customers.component";
import { CustomersAdminComponent } from "./pages/customers-admin/customers-admin.component";
import { CustomersMachinesComponent } from "./pages/customers-machines/customers-machines.component";
import { CustomersUsersComponent } from "./pages/customers-users/customers-users.component";
import { CustomersDetailComponent } from "./pages/customers-detail/customers-detail.component";
import { CustomersOverviewComponent } from "./pages/customers-overview/customers-overview.component";
import { CustomersRoutingModule } from "./customers-routing.module";
import { SharedModule } from "../../shared/shared.module";
import { CustomersNewComponent } from "./pages/customers-new/customers-new.component";


@NgModule({
  declarations: [
    CustomersComponent,
    CustomersNewComponent,
    CustomersAdminComponent,
    CustomersMachinesComponent,
    CustomersUsersComponent,
    CustomersDetailComponent,
    CustomersOverviewComponent

  ],
  imports: [
    SharedModule,
    CustomersRoutingModule
  ]
})
export class CustomersModule {
}

import { Component, computed, effect } from '@angular/core';
import { SidebarItemType } from "../../types/sidebar.model";
import { AuthService } from '../../services/auth.service';
import { DarkmodeService } from "../../services/darkmode.service";
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
  expanded: boolean = true
  isDarkMode = computed(() => this.darkModeService.darkMode());
  nav: SidebarItemType[] | undefined;

  constructor(private authService: AuthService, private darkModeService: DarkmodeService) {
    this.setupNavigation();
    effect(() => {
      this.updateTheme(this.isDarkMode());
    });
  }
  setLightMode(): void {
    this.darkModeService.setDarkMode(false);
  }

  setDarkMode(): void {
    this.darkModeService.setDarkMode(true);
  }

  private updateTheme(isDarkMode: boolean): void {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }

  logout() {
    this.authService.logout();
  }

  private setupNavigation() {
    const baseNav: SidebarItemType[] = [
      {
        title: 'Machine',
        icon: 'inventory_2',
        route: 'machines'
      },
      {
        title: 'Contact',
        icon: 'headset_mic',
        route: 'contacts'
      },
    ];

    if (this.authService.getUserRole() === 'Administrator') {
      this.nav = [...baseNav, {
        title: 'Admin Portal',
        icon: 'assignment_ind',
        route: 'customers'
      }];
    } else {
      this.nav = [...baseNav];
    }
  }
}

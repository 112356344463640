<div class="flex items-center justify-center h-screen">
  <div class="w-full max-w-md p-8 space-y-4 bg-white rounded-2xl shadow-xl">
    <img alt="favicon" class="w-16 h-16 mx-auto" src="../../../assets/images/logo.svg">
    <div *ngIf="!signedIn">
      <h3 class="text-2xl font-extrabold text-center mb-1">Sign in</h3>
      <p class="text-sm text-center mb-2">Effortlessly access your account with our intuitive login form.</p>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit()" class="flex flex-col items-center">
        <input [(ngModel)]="email" class="w-full mb-1 px-4 py-2 text-sm text-center bg-gray-100 rounded-md outline-blue-600" id="email" name="email"
               placeholder="Enter email"
               required>
        <button id="recaptcha-button" class="g-recaptcha w-full py-2 text-sm font-medium text-white bg-blue-400 rounded-lg hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-500"
                type="submit">
          Sign in
        </button>
      </form>
      <div class="mt-6 text-end text-blue-400">
        <a [routerLink]="['/auth/login/register']">No Login yet?</a>
      </div>
    </div>

    <div *ngIf="signedIn" class="text-center">
      <h3 class="text-2xl mb-2 font-extrabold">Successful Sign-in</h3>
      <p class="text-sm">{{ message || "Check your email for the magic link." }}</p>
      <p class="text-sm text-center mb-2">The Email expires in 5 minutes</p>
    </div>
  </div>
</div>

<div class="absolute leading-normal top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
  <span class="headline font-bold text-slate-100">403</span>

</div>
<div class="relative h-[90vh] flex items-center justify-center">
  <div class="text-center">
    <p class="text-4xl font-semibold text-gray-600 mt-4">
      Forbidden
    </p>
    <p class="text-md text-gray-500 mt-2">
      You do not have the required permissions to access this site.
    </p>
    <a href="/" class="mt-6 inline-block text-white bg-blue-400 px-5 py-2 rounded shadow">
      Back to Home Page
    </a>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { NavItem } from "./nav-item.type";


@Component({
  selector: 'app-tab-nav',
  templateUrl: './tab-nav.component.html',
})
export class TabNavComponent {

  @Input() navigation: NavItem[] = [];

}
